<template>
   <div class="container teamReport">
        <div class="row header">
            <i @click="goBack" class="fa fa-angle-left back-button"></i>
            <p class="attendance">Gift</p>
        </div>
        <div data-v-695ca243="" class="redeem-container-header-belly">
            <img data-v-695ca243="" alt="" class="banner" data-origin="../assets/png/gift-d7507b9b.png" src="../assets/png/gift-d7507b9b.png">
        </div>

        <div data-v-695ca243="" class="redeem-container-content">
        <div data-v-695ca243="" class="redeem-container-receive">
            <p data-v-695ca243="">Hi</p>
            <p data-v-695ca243="">We have a gift for you</p>
            <h4 data-v-695ca243="">Please enter the gift code below</h4>
            <form>
                <input data-v-695ca243="" type="text" v-model="giftcode" complete="new-password" autocomplete="off" placeholder="Please enter gift code">
                <button data-v-695ca243="" @click.prevent="getGift()">Receive</button>
            </form>
            <div id="snackbar" class="van-toast van-toast--middle van-toast--text" style="z-index: 2009;display:none ">
                <div class="van-toast__text">success</div>
            </div>
        </div>
        <div data-v-695ca243="" class="redeem-container-record">
            <div data-v-695ca243="" class="redeem-container-record-title">
                <svg data-v-695ca243="" class="svg-icon icon-giftHistory">
                    <use xlink:href="#icon-giftHistory"></use>
                </svg>
                <!-- <img :src="getIcons('main', 'gRecord')" /> --><span data-v-695ca243="">History</span>
            </div>
            <div data-v-f8374830="" class="dailySignInRecord__container-wrapper" v-for="row in rechargerecord" :key="row.id">
        <div data-v-f8374830="" class="dailySignInRecord__container-wrapper__content">
            <div data-v-f8374830="" class="dailySignInRecord__container-wrapper__content-left">
                <h1 data-v-f8374830="" class="text-success">Successfully received</h1>
                <span data-v-f8374830="">{{ row.created }}</span>
            </div>
            <div data-v-f8374830="" class="dailySignInRecord__container-wrapper__content-right">
                <img data-v-f8374830="" alt="" class="" data-origin="../assets/png/balance-e39ce400.png" src="../assets/png/balance-e39ce400.png">
                <span data-v-f8374830="">{{ row.amount }}</span>
            </div>
        </div>
        </div><br>
        <center>No More</center>
        </div>
        </div>
   </div>
</template>
 <script>
    import axios from 'axios';
     export default {
         name: 'GiftCodeView',
         data(){
             return{
                giftcode: null,
                currentTab: 'tab1',
                userdetails: {
                   username: null,
                   total: 0,
                },
                rechargerecord: {},
             }
         },
         beforeCreate: function () {
           if (localStorage.getItem('username') == null)
               this.$router.push('Login');
          },
          created: function() {
             this.getUserdetails();
          },
         methods:{
            pop(message) {
                document.getElementById("snackbar").innerHTML = message;
                document.getElementById("snackbar").style.display = "";
                setTimeout(function () { document.getElementById("snackbar").style.display = "none"; }, 1500);
            },
          goBack() {
             window.history.back();
          },
          getUserdetails(){
            this.userdetails.username = localStorage.getItem('username');
            axios.get('https://manager.royal60club.online/trova/src/api/bet.php?action=giftrec&user=' + this.userdetails.username ).then((res) => {
                this.rechargerecord = res.data;
                console.log(this.rechargerecord);
            }).catch((err) => {
                console.log(err);
            })
          },
          getGift(){
            this.userdetails.username = localStorage.getItem('username');
            if(this.giftcode === null){
               this.pop("Enter gift code");
            }
            else{
                
               axios.get('https://manager.royal60club.online/trova/src/api/bet.php?action=gift&user=' + this.userdetails.username + '&giftcode=' + this.giftcode).then((res) => {
                        console.log("Success", res.data.message);
                        this.pop(res.data.message);
                        this.giftcode = null;
                        setTimeout(() => { this.$router.push("/GiftCode"); }, 600);
                }).catch((err) => {
                    console.log(err);
                })
                }
            },
          setTab(tab) {
                  this.currentTab = tab;
               },
         }
     }
 </script>
 <style>
    
    .attendance{
        margin-left: 42% !important;
    }
    .redeem-container-header-belly{
        margin-left: -3.8%;
    }
    .banner{
        height:200% !important;
        width: 103.6% !important;
    }
    @media screen and (max-width:500) {
        .redeem-container-header-belly{
            margin-left: -12% !important;
        }
        .banner{
            height:200% !important;
            width: 104% !important;
        }
    }
</style>