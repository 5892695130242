<template>
   <div class="game-body bg-white">
      <div class="container-fluid">
         <div data-v-e44179e3="" data-v-5d71c3fd="" class="WinningTip__C"  v-if="userdetails.popup_status === 'show' && userdetails.result === 'fail'">
            <div data-v-e44179e3="" class="WinningTip__C-body isL">
               <div data-v-e44179e3="" class="WinningTip__C-body-l1 isL">Sorry</div>
               <div class="pop__flex">
                  <p style="font-size:13px; color:#768096;">Lottery Results</p>
                  <p style="font-size:13px; color:#fff; border: 1px solid #fff; padding-left:10px; padding-right:10px; border-radius:5px; background:#6889B1; text-transform:capitalize;">{{ userdetails.color }}</p>
                  <p style="font-size:13px; color:#fff; border: 1px solid #fff; padding-left:7px; padding-right:7px; border-radius:20px; background:#6889B1">{{ userdetails.number }}</p>
                  <div v-if="userdetails.number === '5' || userdetails.number === '6' || userdetails.number === '7' || userdetails.number === '8' || userdetails.number === '9'">
                     <p style="font-size:13px; color:#fff; border: 1px solid #fff; padding-left:10px; padding-right:10px; border-radius:5px; background:#6889B1">Big</p>
                  </div>
                  <div v-if="userdetails.number === '0' || userdetails.number === '1' || userdetails.number === '2' || userdetails.number === '3' || userdetails.number === '4'">
                     <p style="font-size:13px; color:#fff; border: 1px solid #fff; padding-left:10px; padding-right:10px; border-radius:5px; background:#6889B1">Small</p> 
                  </div>
               </div>
               <div data-v-e44179e3="" class="WinningTip__C-body-l3">
                  <div data-v-e44179e3="" class="isLose">Lose</div>
                  <div data-v-e44179e3="" class="gameDetail">Period:1 min {{ userdetails.result_period }}</div>
               </div>
               <div data-v-e44179e3="" class="WinningTip__C-body-l4">
                  <div data-v-e44179e3="" class="acitveBtn"></div>
                  3 seconds auto close
               </div>
               <div data-v-e44179e3="" class="closeBtn" @click="close_popup()"></div>
               <!-- <i data-v-e44179e3="" class="van-badge__wrapper van-icon van-icon-arrow arrowBtn" style="color: rgb(255, 255, 255); font-size: 30px; display: none;"></i> -->
            </div>
         </div>
         <div  class="WinningTip__C" v-if="userdetails.result === 'success' && userdetails.popup_status === 'show'">
            <div  class="WinningTip__C-body">
               <div  class="WinningTip__C-body-l1">Congratulations</div>
               <div class="pop__flex">
                  <p style="font-size:13px; color:#fff;">Lottery Results</p>
                  <div v-if="userdetails.number === '2' || userdetails.number === '4' || userdetails.number === '6' || userdetails.number === '8'">
                     <p style="font-size:13px; color:#fff; border: 1px solid #fff; padding-left:10px; padding-right:10px; border-radius:5px; background:var(--main-color)">Red</p>
                  </div>
                  <div v-if="userdetails.number === '1' || userdetails.number === '3' || userdetails.number === '7' || userdetails.number === '9'">
                     <p style="font-size:13px; color:#fff; border: 1px solid #fff; padding-left:10px; padding-right:10px; border-radius:5px; background:var(--norm_green-color)">Green</p>
                  </div>
                  <div v-if="userdetails.number === '5' || userdetails.number === '0'">
                     <p style="font-size:13px; color:#fff; border: 1px solid #fff; padding-left:10px; padding-right:10px; border-radius:5px; background:var(--norm_Purple-color)">Violet</p>
                  </div>
                  <div v-if="userdetails.number === '0' || userdetails.number === '2' || userdetails.number === '4' || userdetails.number === '6' || userdetails.number === '8'">
                     <p style="font-size:13px; color:#fff; border: 1px solid #fff; padding-left:7px; padding-right:7px; border-radius:20px; background:var(--main-color)">{{ userdetails.number }}</p>
                  </div>
                  <div v-if="userdetails.number === '1' || userdetails.number === '3' || userdetails.number === '5' || userdetails.number === '7' || userdetails.number === '9'">
                     <p style="font-size:13px; color:#fff; border: 1px solid #fff; padding-left:7px; padding-right:7px; border-radius:20px; background:var(--norm_green-color)">{{ userdetails.number }}</p>
                  </div>
                  <div v-if="userdetails.number === '5' || userdetails.number === '6' || userdetails.number === '7' || userdetails.number === '8' || userdetails.number === '9'">
                     <p style="font-size:13px; color:#fff; border: 1px solid #fff; padding-left:10px; padding-right:10px; border-radius:5px; background:var(--norm_secondary-color)">Big</p>
                  </div>
                  <div v-if="userdetails.number === '0' || userdetails.number === '1' || userdetails.number === '2' || userdetails.number === '3' || userdetails.number === '4'">
                     <p style="font-size:13px; color:#fff; border: 1px solid #fff; padding-left:10px; padding-right:10px; border-radius:5px; background:var(--norm_bule-color)">Small</p>
                  </div>
               </div>
               <div  class="WinningTip__C-body-l3">
                  <div  class="head">Bonus</div>
                  <div v-if="userdetails.ans === 'red' || userdetails.ans === 'green'">
                     <div v-if="userdetails.number === '1' || userdetails.number === '2' || userdetails.number === '3' || userdetails.number === '4' || userdetails.number === '6' || userdetails.number === '7' || userdetails.number === '8' || userdetails.number === '9'">
                        <div  class="bonus">₹{{ Number(2*(userdetails.amount- userdetails.amount*5/100)).toFixed(2) }}</div>
                     </div>
                     <div v-if="userdetails.number === '0' || userdetails.number === '5'">
                        <div  class="bonus">₹{{ Number(1.5*(userdetails.amount- userdetails.amount*5/100)).toFixed(2) }}</div>
                     </div>
                  </div>
                  <div v-if="userdetails.ans === 'violet'">
                        <div  class="bonus">₹{{ Number(4*(userdetails.amount- userdetails.amount*5/100)).toFixed(2) }}</div>
                  </div>
                  <div v-if="userdetails.ans === 'big' || userdetails.ans === 'small'">
                     <div v-if="userdetails.number === '1' || userdetails.number === '2' || userdetails.number === '3' || userdetails.number === '4' || userdetails.number === '6' || userdetails.number === '7' || userdetails.number === '8' || userdetails.number === '9'">
                        <div  class="bonus">₹{{ Number(2*(userdetails.amount- userdetails.amount*5/100)).toFixed(2) }}</div>
                     </div>
                     <div v-if="userdetails.number === '0' || userdetails.number === '5'">
                        <div  class="bonus">₹{{ Number(2*(userdetails.amount- userdetails.amount*5/100)).toFixed(2) }}</div>
                     </div>
                  </div>
                  <div v-if="userdetails.ans === '0' || userdetails.ans === '1' || userdetails.ans === '2' || userdetails.ans === '3' || userdetails.ans === '4' || userdetails.ans === '5' || userdetails.ans === '6' || userdetails.ans === '7' || userdetails.ans === '8' || userdetails.ans === '9'">
                     <div v-if="userdetails.number === '0' || userdetails.number === '1' || userdetails.number === '2' || userdetails.number === '3' || userdetails.number === '4' || userdetails.number === '5' || userdetails.number === '6' || userdetails.number === '7' || userdetails.number === '8' || userdetails.number === '9'">
                        <div  class="bonus">₹{{ Number(9*(userdetails.amount- userdetails.amount*5/100)).toFixed(2) }}</div>
                     </div>
                  </div>
                  <div  class="gameDetail">Period:1 min {{ userdetails.result_period }}</div>
               </div>
               <div  class="WinningTip__C-body-l4">
                  <div  class="acitveBtn"></div>
                  3 seconds auto close
               </div>
               <div  class="closeBtn" @click="close_popup()"></div>
               <i  class="van-badge__wrapper van-icon van-icon-arrow arrowBtn" style="color: rgb(255, 255, 255); font-size: 30px; display: none;">
                  <!----><!----><!---->
               </i>
            </div>
         </div>
         <div class="row header-fixed">
            <div class="col-1 text-center" @click="$router.push('Home')" style="font-size:20px">
               <i class="fa fa-angle-left"></i>
            </div>
            <div class="col-3"></div>
            <div class="col-5">
               <img src="../assets/png/logo.png" alt="" class="game-logo">
            </div>
            <div class="col-1">
               <center>
                  <img src="../assets/png/kefu-b361c42f.png" alt="" class="customer-support">
               </center>
            </div>
            <div class="col-1">
               <center>
                  <img src="../assets/png/voice-62dbf38c.png" alt="" class="customer-support">
               </center>
            </div>
         </div>
         <div class="row wallet-area-game">
            <div class="payment-display">
               <div class="col-12 text-center pt-3">
                  <b>₹{{ Number(userdetails.balance).toFixed(2) }}<span><img src="../assets/png/refireshIcon-2bc1b49f.png" class="refresh-img" alt=""></span></b>
               </div>
               <div class="col-12">
                  <span>
                     <svg data-v-7dd1adab="" class="svg-icon icon-lottyWallet">
                        <use xlink:href="#icon-lottyWallet"></use>
                     </svg>
                     <p>Wallet Balance</p>
                  </span>
               </div>
               <br>
               <div class="col-6 button">
                  <button class="button btn btn-danger form-control" @click="$router.push('Withdrawal')">
                  Withdraw
                  </button>
               </div>
               <div class="col-6 deposit-section">
                  <button @click="$router.push('Recharge')" class="button btn btn-success form-control">
                  Deposit
                  </button>
               </div>
            </div>
         </div>
         <div data-v-17d56002="" data-v-5d71c3fd="" style="margin-top: -60px;" class="GameList__C">
            <div data-v-17d56002="" class="GameList__C-item" @click="$router.push('wingo')">
               <div data-v-17d56002="">Win Go<br>1Min</div>
            </div>
            <div data-v-17d56002="" class="GameList__C-item active" @click="$router.push('wingo3')">
               <div data-v-17d56002="" >Win Go<br>2Min</div>
            </div>
            <div data-v-17d56002="" class="GameList__C-item" @click="$router.push('wingo5')">
               <div data-v-17d56002="">Win Go<br>3Min</div>
            </div>
            <div data-v-17d56002="" class="GameList__C-item" @click="$router.push('wingo10')">
               <div data-v-17d56002="">Win Go<br>5Min</div>
            </div>
         </div>
         <div class="row timer-section mt-3">
            <div class="col-6 text-center">
               <div class="how-to-play"  data-toggle="modal" data-target="#exampleModalLong">
                  <span>
                     <svg data-v-3e4c6499="" xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 36 36" fill="none" style="color: #fff;" class="float-left">
                        <path data-v-3e4c6499="" d="M23.67 3H12.33C6.66 3 5.25 4.515 5.25 10.56V27.45C5.25 31.44 7.44 32.385 10.095 29.535L10.11 29.52C11.34 28.215 13.215 28.32 14.28 29.745L15.795 31.77C17.01 33.375 18.975 33.375 20.19 31.77L21.705 29.745C22.785 28.305 24.66 28.2 25.89 29.52C28.56 32.37 30.735 31.425 30.735 27.435V10.56C30.75 4.515 29.34 3 23.67 3ZM11.67 18C10.845 18 10.17 17.325 10.17 16.5C10.17 15.675 10.845 15 11.67 15C12.495 15 13.17 15.675 13.17 16.5C13.17 17.325 12.495 18 11.67 18ZM11.67 12C10.845 12 10.17 11.325 10.17 10.5C10.17 9.675 10.845 9 11.67 9C12.495 9 13.17 9.675 13.17 10.5C13.17 11.325 12.495 12 11.67 12ZM24.345 17.625H16.095C15.48 17.625 14.97 17.115 14.97 16.5C14.97 15.885 15.48 15.375 16.095 15.375H24.345C24.96 15.375 25.47 15.885 25.47 16.5C25.47 17.115 24.96 17.625 24.345 17.625ZM24.345 11.625H16.095C15.48 11.625 14.97 11.115 14.97 10.5C14.97 9.885 15.48 9.375 16.095 9.375H24.345C24.96 9.375 25.47 9.885 25.47 10.5C25.47 11.115 24.96 11.625 24.345 11.625Z" fill="currentColor"></path>
                     </svg>
                     <p>How to play</p>
                  </span>
                  <p class="float-left ml-1" style="margin-top: -14px;">Win Go 2 Min</p>
               </div>
            </div>
            <div class="col-6 text-right">
               <div class="remaning">
                  <b>Time remaining</b>
                  <div>
                     <span class="timer digit">{{ minutesString[0] }}</span>
                     <span class="timer digit">{{ minutesString[1] }}</span>
                     <span class="timer digit">:</span>
                     <span class="timer digit">{{ seconds[0] }}</span>
                     <span class="timer digit">{{ seconds[1] }}</span>
                  </div>
                  <!-- <b class="period_id"> {{userdetails.period}} </b> -->
                  <b class="period_id"> {{ userdetails.period }} </b>
               </div>
            </div>
            <div class="last5-result" v-for="item in limitedItems" :key="item.id">
               <div v-if="item.ans==='0'">
                  <img src="../assets/png/n0-30bd92d1.png" alt="">
               </div>
               <div v-if="item.ans==='1'">
                  <img src="../assets/png/n1-dfccbff5.png" alt="">
               </div>
               <div v-if="item.ans==='2'">
                  <img src="../assets/png/n2-c2913607.png" alt="">
               </div>
               <div v-if="item.ans==='3'">
                  <img src="../assets/png/n3-f92c313f.png" alt="">
               </div>
               <div v-if="item.ans==='4'">
                  <img src="../assets/png/n4-cb84933b.png" alt="">
               </div>
               <div v-if="item.ans==='5'">
                  <img src="../assets/png/n5-49d0e9c5.png" alt="">
               </div>
               <div v-if="item.ans==='6'">
                  <img src="../assets/png/n6-a56e0b9a.png" alt="">
               </div>
               <div v-if="item.ans==='7'">
                  <img src="../assets/png/n7-5961a17f.png" alt="">
               </div>
               <div v-if="item.ans==='8'">
                  <img src="../assets/png/n8-d4d951a4.png" alt="">
               </div>
               <div v-if="item.ans==='9'">
                  <img src="../assets/png/n9-a20f6f42.png" alt="">
               </div>
               <!-- <div v-else>
                  <img src="../assets/png/n5-49d0e9c5.png" alt="">
               </div> -->
            </div>
         </div>
         <div class="row betting-area">
            <div class="col-4 text-center">
               <button class="btn green" data-toggle="modal" data-target="#green">Green</button>
            </div>
            <div class="col-4 text-center">
               <button class="btn violet" data-toggle="modal" data-target="#violet">Violet</button>
            </div>
            <div class="col-4 text-center">
               <button class="btn red" data-toggle="modal" data-target="#red">Red</button>
            </div>
            <div class="col-12 number-trade">
               <img src="../assets/png/n0-30bd92d1.png" alt="" data-toggle="modal" data-target="#zero">
               <img src="../assets/png/n1-dfccbff5.png" alt="" data-toggle="modal" data-target="#one">
               <img src="../assets/png/n2-c2913607.png" alt="" data-toggle="modal" data-target="#two">
               <img src="../assets/png/n3-f92c313f.png" alt="" data-toggle="modal" data-target="#three">
               <img src="../assets/png/n4-cb84933b.png" alt="" data-toggle="modal" data-target="#four">
            </div>
            <div class="col-12 number-trade1">
               <img src="../assets/png/n5-49d0e9c5.png" alt="" data-toggle="modal" data-target="#five">
               <img src="../assets/png/n6-a56e0b9a.png" alt="" data-toggle="modal" data-target="#six">
               <img src="../assets/png/n7-5961a17f.png" alt="" data-toggle="modal" data-target="#seven">
               <img src="../assets/png/n8-d4d951a4.png" alt="" data-toggle="modal" data-target="#eight">
               <img src="../assets/png/n9-a20f6f42.png" alt="" data-toggle="modal" data-target="#nine">
            </div>
            <div data-v-4aca9bd1="" class="Betting__C-mark" v-if="showSecondTimer">
               <span class="digit" data-v-4aca9bd1="">{{ seconds[0] }}</span>
               <span class="digit" data-v-4aca9bd1="">{{ seconds[1] }}</span>
            </div>
         </div>
         <div class="row big-small">
            <div data-v-4aca9bd1="" class="Betting__C-multiple">
               <button class="random">Random</button>
               <button class="active">1x</button>
               <button class="x">5x</button>
               <button class="x">10x</button>
               <button class="x">20x</button>
               <button class="x">50x</button>
               <button class="x">100x</button>
            </div>
            <div class="big-small">
               <button class="big" data-toggle="modal" data-target="#big">Big</button>
               <button class="small-button" data-toggle="modal" data-target="#small">Small</button>
            </div>
         </div>
         <div data-v-72f81e71="" data-v-5d71c3fd="" class="RecordNav__C">
            <div data-v-72f81e71="" :class="{ active: currentTab === 'tab1' }"
               @click="setTab('tab1')">Game history</div>
            <div data-v-72f81e71="" :class="{ active: currentTab === 'tab2' }"
               @click="setTab('tab2')">Chart</div>
            <div data-v-72f81e71="" :class="{ active: currentTab === 'tab3' }"
               @click="setTab('tab3')">My history</div>
         </div>
         <!-- tab content-->
         <div v-if="currentTab === 'tab1'">
            <div class="game__history row">
               <div class="col-4 text-center">
                  <b>Period</b>
               </div>
               <div class="col-3 text-center">
                  <b>Number</b>
               </div>
               <div class="col-3 text-center">
                  <b>Big Small</b>
               </div>
               <div class="col-2 text-center">
                  <b>Color</b>
               </div>
            </div>
            <div class="game__history_body row" v-for="item in items" :key="item.id">
               <div class="col-4 text-center">
                  <p>{{item.period}}</p>
               </div>
               <div class="col-3 text-center" >
                  <div v-if="item.ans === '0'">
                     <b class="mixedColor0">{{item.ans}}</b>
                  </div>
                  <div v-if="item.ans === '1'">
                     <b class="greenColor">{{item.ans}}</b>
                  </div>
                  <div v-if="item.ans === '2'">
                     <b class="redColor">{{item.ans}}</b>
                  </div>
                  <div v-if="item.ans === '3'">
                     <b class="greenColor">{{item.ans}}</b>
                  </div>
                  <div v-if="item.ans === '4'">
                     <b class="redColor">{{item.ans}}</b>
                  </div>
                  <div v-if="item.ans === '5'">
                     <b class="mixedColor5">{{item.ans}}</b>
                  </div>
                  <div v-if="item.ans === '6'">
                     <b class="redColor">{{item.ans}}</b>
                  </div>
                  <div v-if="item.ans === '7'">
                     <b class="greenColor">{{item.ans}}</b>
                  </div>
                  <div v-if="item.ans === '8'">
                     <b class="redColor">{{item.ans}}</b>
                  </div>
                  <div v-if="item.ans === '9'">
                     <b class="greenColor">{{item.ans}}</b>
                  </div>
               </div>
               <div class="col-3 text-center text-capitalize">
                  <div v-if="item.ans==='0' || item.ans==='1' || item.ans==='2' || item.ans==='3' || item.ans==='4'">
                     <p>Small</p>
                  </div>
                  <div v-if="item.ans==='5' || item.ans==='6' || item.ans==='7' || item.ans==='8' || item.ans==='9'">
                     <p>Big</p>
                  </div>
               </div>
               <div class="col-2">
                  <div v-if="item.ans === '0'" style="display: flex; gap: 0.5rem;">
                     <p class="GameRecord__C-origin-I red" ></p>
                     <p class="GameRecord__C-origin-I violet"></p>
                  </div>
                  <div v-if="item.ans === '1' || item.ans === '3' || item.ans === '7' || item.ans === '9'">
                     <p class="GameRecord__C-origin-I green" style="margin-left: 10px;"></p>
                  </div>
                  <div v-if="item.ans === '2' || item.ans === '4' || item.ans === '6' || item.ans === '8'">
                     <p class="GameRecord__C-origin-I red" style="margin-left: 10px;"></p>
                  </div>
                  <div v-if="item.ans === '5'" style="display: flex; gap: 0.5rem;">
                     <p class="GameRecord__C-origin-I green"></p>
                     <p class="GameRecord__C-origin-I violet"></p>
                  </div>
               </div>
            </div>
            <div class="row">
               <div data-v-481307ec="" class="GameRecord__C-foot">
                  <div v-if="betbox.page===1">
                     <div data-v-481307ec="" class="GameRecord__C-foot-previous disabled">
                        <i data-v-481307ec="" @click="down()" class="van-badge__wrapper van-icon van-icon-arrow-left GameRecord__C-icon fa fa-angle-left" style="font-size: 20px;">
                        <!----><!----><!---->
                        </i>
                     </div>
                  </div>
                  <div v-if="betbox.page>1">
                     <div data-v-481307ec="" class="GameRecord__C-foot-previous">
                        <i data-v-481307ec="" @click="down()" class="van-badge__wrapper van-icon van-icon-arrow-left GameRecord__C-icon fa fa-angle-left" style="font-size: 20px;">
                        <!----><!----><!---->
                        </i>
                     </div>
                  </div>
                  <div data-v-481307ec="" class="GameRecord__C-foot-page">{{(betbox.page)*1}}/{{betbox.total}}</div>
                     <div v-if="betbox.page<1000">
                        <div data-v-481307ec="" class="GameRecord__C-foot-next">
                        <i data-v-481307ec="" @click="up()" class="van-badge__wrapper van-icon van-icon-arrow GameRecord__C-icon fa fa-angle-right" style="font-size: 20px;">
                           <!----><!----><!---->
                        </i>
                        </div>
                     </div>
                     <div v-if="betbox.page===1000">
                        <div data-v-481307ec="" class="GameRecord__C-foot-next disabled">
                        <i data-v-481307ec="" @click="up()" class="van-badge__wrapper van-icon van-icon-arrow GameRecord__C-icon fa fa-angle-right" style="font-size: 20px;">
                           <!----><!----><!---->
                        </i>
                        </div>
                     </div>
                  </div>
            </div>
         </div>
         <div v-if="currentTab === 'tab2'">
            <div data-v-d485a39d="" data-v-5d71c3fd="" class="Trend__C game-record" apifun="e=>_(f.WinGoGetMyEmerdList,e).then(t=>t.data)" gopathname="AllLotteryGames-BettingRecordWin">
            <div data-v-d485a39d="" class="Trend__C-head">
               <div data-v-d485a39d="" class="row">
                  <div data-v-d485a39d="" class="col-6">Period</div>
                  <div data-v-d485a39d="" class="col-6">Number</div>
               </div>
            </div>
            <div data-v-d485a39d="" class="Trend__C-body1">
               <div data-v-d485a39d="" class="Trend__C-body1-line pb-4">Statistic (last 100 Periods)</div>
               <div data-v-d485a39d="" class="Trend__C-body1-line lottery">
                  <div data-v-d485a39d="">Winning number</div>
                  <div data-v-d485a39d="" class="Trend__C-body1-line-num">
                     <div data-v-d485a39d="">0</div>
                     <div data-v-d485a39d="">1</div>
                     <div data-v-d485a39d="">2</div>
                     <div data-v-d485a39d="">3</div>
                     <div data-v-d485a39d="">4</div>
                     <div data-v-d485a39d="">5</div>
                     <div data-v-d485a39d="">6</div>
                     <div data-v-d485a39d="">7</div>
                     <div data-v-d485a39d="">8</div>
                     <div data-v-d485a39d="">9</div>
                  </div>
               </div>
               <div data-v-d485a39d="" class="Trend__C-body1-line">
                  <div data-v-d485a39d="">Missing</div>
                  <div data-v-d485a39d="" class="Trend__C-body1-line-num">
                     <div data-v-d485a39d="">17</div>
                     <div data-v-d485a39d="">8</div>
                     <div data-v-d485a39d="">4</div>
                     <div data-v-d485a39d="">10</div>
                     <div data-v-d485a39d="">13</div>
                     <div data-v-d485a39d="">1</div>
                     <div data-v-d485a39d="">5</div>
                     <div data-v-d485a39d="">3</div>
                     <div data-v-d485a39d="">0</div>
                     <div data-v-d485a39d="">14</div>
                  </div>
               </div>
               <div data-v-d485a39d="" class="Trend__C-body1-line">
                  <div data-v-d485a39d="">Avg missing</div>
                  <div data-v-d485a39d="" class="Trend__C-body1-line-num">
                     <div data-v-d485a39d="">10</div>
                     <div data-v-d485a39d="">10</div>
                     <div data-v-d485a39d="">6</div>
                     <div data-v-d485a39d="">10</div>
                     <div data-v-d485a39d="">13</div>
                     <div data-v-d485a39d="">9</div>
                     <div data-v-d485a39d="">10</div>
                     <div data-v-d485a39d="">6</div>
                     <div data-v-d485a39d="">10</div>
                     <div data-v-d485a39d="">8</div>
                  </div>
               </div>
               <div data-v-d485a39d="" class="Trend__C-body1-line">
                  <div data-v-d485a39d="">Frequency</div>
                  <div data-v-d485a39d="" class="Trend__C-body1-line-num">
                     <div data-v-d485a39d="">9</div>
                     <div data-v-d485a39d="">8</div>
                     <div data-v-d485a39d="">13</div>
                     <div data-v-d485a39d="">10</div>
                     <div data-v-d485a39d="">8</div>
                     <div data-v-d485a39d="">9</div>
                     <div data-v-d485a39d="">8</div>
                     <div data-v-d485a39d="">14</div>
                     <div data-v-d485a39d="">10</div>
                     <div data-v-d485a39d="">11</div>
                  </div>
               </div>
               <div data-v-d485a39d="" class="Trend__C-body1-line">
                  <div data-v-d485a39d="">Max consecutive</div>
                  <div data-v-d485a39d="" class="Trend__C-body1-line-num">
                     <div data-v-d485a39d="">2</div>
                     <div data-v-d485a39d="">1</div>
                     <div data-v-d485a39d="">1</div>
                     <div data-v-d485a39d="">2</div>
                     <div data-v-d485a39d="">2</div>
                     <div data-v-d485a39d="">1</div>
                     <div data-v-d485a39d="">1</div>
                     <div data-v-d485a39d="">2</div>
                     <div data-v-d485a39d="">2</div>
                     <div data-v-d485a39d="">2</div>
                  </div>
               </div>
            </div>
            <div data-v-d485a39d="" class="Trend__C-body2">
               <div data-v-d485a39d="" issuenumber="20240806010466" number="8" colour="red" rowid="0">
                  <div data-v-d485a39d="" class="row" style="border-bottom:0.5px solid #758096; padding-bottom:10px; padding-top:10px;" v-for="item in items" :key="item.id">
                     <div data-v-d485a39d="" class="col-4">
                        <div data-v-d485a39d="" class="Trend__C-body2-IssueNumber">{{ item.period }}</div>
                     </div>
                     <div data-v-d485a39d="" class="col-8">
                        <div data-v-d485a39d="" class="Trend__C-body2-Num" v-if="item.ans==='0'">
                           <canvas data-v-d485a39d="" canvas="" id="myCanvas0" class="line-canvas"></canvas>
                           <div data-v-d485a39d="" class="Trend__C-body2-Num-item action0">0</div>
                           <div data-v-d485a39d="" class="Trend__C-body2-Num-item">1</div>
                           <div data-v-d485a39d="" class="Trend__C-body2-Num-item">2</div>
                           <div data-v-d485a39d="" class="Trend__C-body2-Num-item">3</div>
                           <div data-v-d485a39d="" class="Trend__C-body2-Num-item">4</div>
                           <div data-v-d485a39d="" class="Trend__C-body2-Num-item">5</div>
                           <div data-v-d485a39d="" class="Trend__C-body2-Num-item">6</div>
                           <div data-v-d485a39d="" class="Trend__C-body2-Num-item">7</div>
                           <div data-v-d485a39d="" class="Trend__C-body2-Num-item">8</div>
                           <div data-v-d485a39d="" class="Trend__C-body2-Num-item">9</div>
                           <div data-v-d485a39d="" class="Trend__C-body2-Num-BS isS">S</div>
                        </div>
                        <div data-v-d485a39d="" class="Trend__C-body2-Num" v-if="item.ans==='1'">
                           <canvas data-v-d485a39d="" canvas="" id="myCanvas0" class="line-canvas"></canvas>
                           <div data-v-d485a39d="" class="Trend__C-body2-Num-item">0</div>
                           <div data-v-d485a39d="" class="Trend__C-body2-Num-item action1">1</div>
                           <div data-v-d485a39d="" class="Trend__C-body2-Num-item">2</div>
                           <div data-v-d485a39d="" class="Trend__C-body2-Num-item">3</div>
                           <div data-v-d485a39d="" class="Trend__C-body2-Num-item">4</div>
                           <div data-v-d485a39d="" class="Trend__C-body2-Num-item">5</div>
                           <div data-v-d485a39d="" class="Trend__C-body2-Num-item">6</div>
                           <div data-v-d485a39d="" class="Trend__C-body2-Num-item">7</div>
                           <div data-v-d485a39d="" class="Trend__C-body2-Num-item">8</div>
                           <div data-v-d485a39d="" class="Trend__C-body2-Num-item">9</div>
                           <div data-v-d485a39d="" class="Trend__C-body2-Num-BS isS">S</div>
                        </div>
                        <div data-v-d485a39d="" class="Trend__C-body2-Num" v-if="item.ans==='2'">
                           <canvas data-v-d485a39d="" canvas="" id="myCanvas0" class="line-canvas"></canvas>
                           <div data-v-d485a39d="" class="Trend__C-body2-Num-item">0</div>
                           <div data-v-d485a39d="" class="Trend__C-body2-Num-item">1</div>
                           <div data-v-d485a39d="" class="Trend__C-body2-Num-item action2">2</div>
                           <div data-v-d485a39d="" class="Trend__C-body2-Num-item">3</div>
                           <div data-v-d485a39d="" class="Trend__C-body2-Num-item">4</div>
                           <div data-v-d485a39d="" class="Trend__C-body2-Num-item">5</div>
                           <div data-v-d485a39d="" class="Trend__C-body2-Num-item">6</div>
                           <div data-v-d485a39d="" class="Trend__C-body2-Num-item">7</div>
                           <div data-v-d485a39d="" class="Trend__C-body2-Num-item">8</div>
                           <div data-v-d485a39d="" class="Trend__C-body2-Num-item">9</div>
                           <div data-v-d485a39d="" class="Trend__C-body2-Num-BS isS">S</div>
                        </div>
                        <div data-v-d485a39d="" class="Trend__C-body2-Num" v-if="item.ans==='3'">
                           <canvas data-v-d485a39d="" canvas="" id="myCanvas0" class="line-canvas"></canvas>
                           <div data-v-d485a39d="" class="Trend__C-body2-Num-item">0</div>
                           <div data-v-d485a39d="" class="Trend__C-body2-Num-item">1</div>
                           <div data-v-d485a39d="" class="Trend__C-body2-Num-item">2</div>
                           <div data-v-d485a39d="" class="Trend__C-body2-Num-item action3">3</div>
                           <div data-v-d485a39d="" class="Trend__C-body2-Num-item">4</div>
                           <div data-v-d485a39d="" class="Trend__C-body2-Num-item">5</div>
                           <div data-v-d485a39d="" class="Trend__C-body2-Num-item">6</div>
                           <div data-v-d485a39d="" class="Trend__C-body2-Num-item">7</div>
                           <div data-v-d485a39d="" class="Trend__C-body2-Num-item">8</div>
                           <div data-v-d485a39d="" class="Trend__C-body2-Num-item">9</div>
                           <div data-v-d485a39d="" class="Trend__C-body2-Num-BS isS">S</div>
                        </div>
                        <div data-v-d485a39d="" class="Trend__C-body2-Num" v-if="item.ans==='4'">
                           <canvas data-v-d485a39d="" canvas="" id="myCanvas0" class="line-canvas"></canvas>
                           <div data-v-d485a39d="" class="Trend__C-body2-Num-item">0</div>
                           <div data-v-d485a39d="" class="Trend__C-body2-Num-item">1</div>
                           <div data-v-d485a39d="" class="Trend__C-body2-Num-item">2</div>
                           <div data-v-d485a39d="" class="Trend__C-body2-Num-item">3</div>
                           <div data-v-d485a39d="" class="Trend__C-body2-Num-item action4">4</div>
                           <div data-v-d485a39d="" class="Trend__C-body2-Num-item">5</div>
                           <div data-v-d485a39d="" class="Trend__C-body2-Num-item">6</div>
                           <div data-v-d485a39d="" class="Trend__C-body2-Num-item">7</div>
                           <div data-v-d485a39d="" class="Trend__C-body2-Num-item">8</div>
                           <div data-v-d485a39d="" class="Trend__C-body2-Num-item">9</div>
                           <div data-v-d485a39d="" class="Trend__C-body2-Num-BS isS">S</div>
                        </div>
                        <div data-v-d485a39d="" class="Trend__C-body2-Num" v-if="item.ans==='5'">
                           <canvas data-v-d485a39d="" canvas="" id="myCanvas0" class="line-canvas"></canvas>
                           <div data-v-d485a39d="" class="Trend__C-body2-Num-item">0</div>
                           <div data-v-d485a39d="" class="Trend__C-body2-Num-item">1</div>
                           <div data-v-d485a39d="" class="Trend__C-body2-Num-item">2</div>
                           <div data-v-d485a39d="" class="Trend__C-body2-Num-item">3</div>
                           <div data-v-d485a39d="" class="Trend__C-body2-Num-item">4</div>
                           <div data-v-d485a39d="" class="Trend__C-body2-Num-item action5">5</div>
                           <div data-v-d485a39d="" class="Trend__C-body2-Num-item">6</div>
                           <div data-v-d485a39d="" class="Trend__C-body2-Num-item">7</div>
                           <div data-v-d485a39d="" class="Trend__C-body2-Num-item">8</div>
                           <div data-v-d485a39d="" class="Trend__C-body2-Num-item">9</div>
                           <div data-v-d485a39d="" class="Trend__C-body2-Num-BS isB">B</div>
                        </div>
                        <div data-v-d485a39d="" class="Trend__C-body2-Num" v-if="item.ans==='6'">
                           <canvas data-v-d485a39d="" canvas="" id="myCanvas0" class="line-canvas"></canvas>
                           <div data-v-d485a39d="" class="Trend__C-body2-Num-item">0</div>
                           <div data-v-d485a39d="" class="Trend__C-body2-Num-item">1</div>
                           <div data-v-d485a39d="" class="Trend__C-body2-Num-item">2</div>
                           <div data-v-d485a39d="" class="Trend__C-body2-Num-item">3</div>
                           <div data-v-d485a39d="" class="Trend__C-body2-Num-item">4</div>
                           <div data-v-d485a39d="" class="Trend__C-body2-Num-item">5</div>
                           <div data-v-d485a39d="" class="Trend__C-body2-Num-item action6">6</div>
                           <div data-v-d485a39d="" class="Trend__C-body2-Num-item">7</div>
                           <div data-v-d485a39d="" class="Trend__C-body2-Num-item">8</div>
                           <div data-v-d485a39d="" class="Trend__C-body2-Num-item">9</div>
                           <div data-v-d485a39d="" class="Trend__C-body2-Num-BS isB">B</div>
                        </div>
                        <div data-v-d485a39d="" class="Trend__C-body2-Num" v-if="item.ans==='7'">
                           <canvas data-v-d485a39d="" canvas="" id="myCanvas0" class="line-canvas"></canvas>
                           <div data-v-d485a39d="" class="Trend__C-body2-Num-item">0</div>
                           <div data-v-d485a39d="" class="Trend__C-body2-Num-item">1</div>
                           <div data-v-d485a39d="" class="Trend__C-body2-Num-item">2</div>
                           <div data-v-d485a39d="" class="Trend__C-body2-Num-item">3</div>
                           <div data-v-d485a39d="" class="Trend__C-body2-Num-item">4</div>
                           <div data-v-d485a39d="" class="Trend__C-body2-Num-item">5</div>
                           <div data-v-d485a39d="" class="Trend__C-body2-Num-item">6</div>
                           <div data-v-d485a39d="" class="Trend__C-body2-Num-item action7">7</div>
                           <div data-v-d485a39d="" class="Trend__C-body2-Num-item">8</div>
                           <div data-v-d485a39d="" class="Trend__C-body2-Num-item">9</div>
                           <div data-v-d485a39d="" class="Trend__C-body2-Num-BS isB">B</div>
                        </div>
                        <div data-v-d485a39d="" class="Trend__C-body2-Num" v-if="item.ans==='8'">
                           <canvas data-v-d485a39d="" canvas="" id="myCanvas0" class="line-canvas"></canvas>
                           <div data-v-d485a39d="" class="Trend__C-body2-Num-item">0</div>
                           <div data-v-d485a39d="" class="Trend__C-body2-Num-item">1</div>
                           <div data-v-d485a39d="" class="Trend__C-body2-Num-item">2</div>
                           <div data-v-d485a39d="" class="Trend__C-body2-Num-item">3</div>
                           <div data-v-d485a39d="" class="Trend__C-body2-Num-item">4</div>
                           <div data-v-d485a39d="" class="Trend__C-body2-Num-item">5</div>
                           <div data-v-d485a39d="" class="Trend__C-body2-Num-item">6</div>
                           <div data-v-d485a39d="" class="Trend__C-body2-Num-item">7</div>
                           <div data-v-d485a39d="" class="Trend__C-body2-Num-item action8">8</div>
                           <div data-v-d485a39d="" class="Trend__C-body2-Num-item">9</div>
                           <div data-v-d485a39d="" class="Trend__C-body2-Num-BS isB">B</div>
                        </div>
                        <div data-v-d485a39d="" class="Trend__C-body2-Num" v-if="item.ans==='9'">
                           <canvas data-v-d485a39d="" canvas="" id="myCanvas0" class="line-canvas"></canvas>
                           <div data-v-d485a39d="" class="Trend__C-body2-Num-item">0</div>
                           <div data-v-d485a39d="" class="Trend__C-body2-Num-item">1</div>
                           <div data-v-d485a39d="" class="Trend__C-body2-Num-item">2</div>
                           <div data-v-d485a39d="" class="Trend__C-body2-Num-item">3</div>
                           <div data-v-d485a39d="" class="Trend__C-body2-Num-item">4</div>
                           <div data-v-d485a39d="" class="Trend__C-body2-Num-item">5</div>
                           <div data-v-d485a39d="" class="Trend__C-body2-Num-item">6</div>
                           <div data-v-d485a39d="" class="Trend__C-body2-Num-item">7</div>
                           <div data-v-d485a39d="" class="Trend__C-body2-Num-item">8</div>
                           <div data-v-d485a39d="" class="Trend__C-body2-Num-item action9">9</div>
                           <div data-v-d485a39d="" class="Trend__C-body2-Num-BS isB">B</div>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
            <!-- <div data-v-d485a39d="" class="Trend__C-foot">
               <div data-v-d485a39d="" class="Trend__C-foot-previous disabled">
                  <i data-v-d485a39d="" class="van-badge__wrapper van-icon van-icon-arrow-left Trend__C-icon" style="font-size: 20px;">
                     
                  </i>
               </div>
               <div data-v-d485a39d="" class="Trend__C-foot-page">1/1631</div>
               <div data-v-d485a39d="" class="Trend__C-foot-next">
                  <i data-v-d485a39d="" class="van-badge__wrapper van-icon van-icon-arrow Trend__C-icon" style="font-size: 20px;">
                     
                  </i>
               </div>
            </div> -->
         </div>
         </div>
         <div v-if="currentTab === 'tab3'">
            <div class="MyGames__history">
               <div class="details___button">Details <i class="fa fa-angle-right"></i></div>
               <div v-for="rows in betrec" :key="rows.id" :rowsrec="rows">
               <div class="history__flex" @click="toggle(rows.id)"> 
                  <div v-if="rows.ans === 'green'">
                     <div class="Box__Green">
                     </div>
                  </div>
                  <div v-if="rows.ans === 'red'">
                     <div class="Box__Red">
                     </div>
                  </div>
                  <div v-if="rows.ans === 'violet'">
                     <div class="Box__Violet">
                     </div>
                  </div>
                  <div v-if="rows.ans === 'big'">
                     <div class="Box__Big">
                        <small style="font-size: 11px;">Big</small>
                     </div>
                  </div>
                  <div v-if="rows.ans === 'small'">
                     <div class="Box__Small">
                        <small style="font-size: 11px;">Small</small>
                     </div>
                  </div>
                  <div v-if="rows.ans === '0'">
                     <div class="Box__0">
                        {{ rows.ans }}
                     </div>
                  </div>
                  <div v-if="rows.ans === '5'">
                     <div class="Box__5">
                        {{ rows.ans }}
                     </div>
                  </div>
                  <div v-if="rows.ans === '1' || rows.ans === '3' || rows.ans === '7' || rows.ans === '9'">
                     <div class="Box__Green">
                        {{ rows.ans }}
                     </div>
                  </div>
                  <div v-if="rows.ans === '2' || rows.ans === '4' || rows.ans === '6' || rows.ans === '8'">
                     <div class="Box__Red">
                        {{ rows.ans }}
                     </div>
                  </div>
                  <div class="period__hist">
                     <p>{{ rows.period }}</p>
                     <p style="margin-top:-10px">{{ rows.time }}</p>
                  </div>
                  <div v-if="rows.res === 'success'">
                     <div v-if="rows.ans==='violet'">
                        <div class="bet__status_success">
                           <p>Success</p>
                           <p style="margin-top:-10px">₹{{ Number(4*(rows.amount- rows.amount*5/100)).toFixed(2) }}</p>
                        </div>
                     </div>
                     <div v-if="rows.ans==='red' && rows.number==='0'">
                        <div class="bet__status_success">
                           <p>Success</p>
                           <p style="margin-top:-10px">₹{{ Number(1.5*(rows.amount- rows.amount*5/100)).toFixed(2) }}</p>
                        </div>
                     </div>
                     <div v-if="rows.ans==='red' && rows.number==='2'">
                        <div class="bet__status_success">
                           <p>Success</p>
                           <p style="margin-top:-10px">₹{{ Number(2*(rows.amount- rows.amount*5/100)).toFixed(2) }}</p>
                        </div>
                     </div>
                     <div v-if="rows.ans==='red' && rows.number==='4'">
                        <div class="bet__status_success">
                           <p>Success</p>
                           <p style="margin-top:-10px">₹{{ Number(2*(rows.amount- rows.amount*5/100)).toFixed(2) }}</p>
                        </div>
                     </div>
                     <div v-if="rows.ans==='red' && rows.number==='6'">
                        <div class="bet__status_success">
                           <p>Success</p>
                           <p style="margin-top:-10px">₹{{ Number(2*(rows.amount- rows.amount*5/100)).toFixed(2) }}</p>
                        </div>
                     </div>
                     <div v-if="rows.ans==='red' && rows.number==='8'">
                        <div class="bet__status_success">
                           <p>Success</p>
                           <p style="margin-top:-10px">₹{{ Number(2*(rows.amount- rows.amount*5/100)).toFixed(2) }}</p>
                        </div>
                     </div>
                     <div v-if="rows.ans==='green' && rows.number==='1'">
                        <div class="bet__status_success">
                           <p>Success</p>
                           <p style="margin-top:-10px">₹{{ Number(2*(rows.amount- rows.amount*5/100)).toFixed(2) }}</p>
                        </div>
                     </div>
                     <div v-if="rows.ans==='green' && rows.number==='3'">
                        <div class="bet__status_success">
                           <p>Success</p>
                           <p style="margin-top:-10px">₹{{ Number(2*(rows.amount- rows.amount*5/100)).toFixed(2) }}</p>
                        </div>
                     </div>
                     <div v-if="rows.ans==='green' && rows.number==='5'">
                        <div class="bet__status_success">
                           <p>Success</p>
                           <p style="margin-top:-10px">₹{{ Number(1.5*(rows.amount- rows.amount*5/100)).toFixed(2) }}</p>
                        </div>
                     </div>
                     <div v-if="rows.ans==='green' && rows.number==='7'">
                        <div class="bet__status_success">
                           <p>Success</p>
                           <p style="margin-top:-10px">₹{{ Number(2*(rows.amount- rows.amount*5/100)).toFixed(2) }}</p>
                        </div>
                     </div>
                     <div v-if="rows.ans==='green' && rows.number==='9'">
                        <div class="bet__status_success">
                           <p>Success</p>
                           <p style="margin-top:-10px">₹{{ Number(2*(rows.amount- rows.amount*5/100)).toFixed(2) }}</p>
                        </div>
                     </div>
                     <div v-if="rows.ans==='small' && rows.number==='0'">
                        <div class="bet__status_success">
                           <p>Success</p>
                           <p style="margin-top:-10px">₹{{ Number(2*(rows.amount- rows.amount*5/100)).toFixed(2) }}</p>
                        </div>
                     </div>
                     <div v-if="rows.ans==='small' && rows.number==='1'">
                        <div class="bet__status_success">
                           <p>Success</p>
                           <p style="margin-top:-10px">₹{{ Number(2*(rows.amount- rows.amount*5/100)).toFixed(2) }}</p>
                        </div>
                     </div>
                     <div v-if="rows.ans==='small' && rows.number==='2'">
                        <div class="bet__status_success">
                           <p>Success</p>
                           <p style="margin-top:-10px">₹{{ Number(2*(rows.amount- rows.amount*5/100)).toFixed(2) }}</p>
                        </div>
                     </div>
                     <div v-if="rows.ans==='small' && rows.number==='3'">
                        <div class="bet__status_success">
                           <p>Success</p>
                           <p style="margin-top:-10px">₹{{ Number(2*(rows.amount- rows.amount*5/100)).toFixed(2) }}</p>
                        </div>
                     </div>
                     <div v-if="rows.ans==='small' && rows.number==='4'">
                        <div class="bet__status_success">
                           <p>Success</p>
                           <p style="margin-top:-10px">₹{{ Number(2*(rows.amount- rows.amount*5/100)).toFixed(2) }}</p>
                        </div>
                     </div>
                     <div v-if="rows.ans==='big' && rows.number==='5'">
                        <div class="bet__status_success">
                           <p>Success</p>
                           <p style="margin-top:-10px">₹{{ Number(2*(rows.amount- rows.amount*5/100)).toFixed(2) }}</p>
                        </div>
                     </div>
                     <div v-if="rows.ans==='big' && rows.number==='6'">
                        <div class="bet__status_success">
                           <p>Success</p>
                           <p style="margin-top:-10px">₹{{ Number(2*(rows.amount- rows.amount*5/100)).toFixed(2) }}</p>
                        </div>
                     </div>
                     <div v-if="rows.ans==='big' && rows.number==='7'">
                        <div class="bet__status_success">
                           <p>Success</p>
                           <p style="margin-top:-10px">₹{{ Number(2*(rows.amount- rows.amount*5/100)).toFixed(2) }}</p>
                        </div>
                     </div>
                     <div v-if="rows.ans==='big' && rows.number==='8'">
                        <div class="bet__status_success">
                           <p>Success</p>
                           <p style="margin-top:-10px">₹{{ Number(2*(rows.amount- rows.amount*5/100)).toFixed(2) }}</p>
                        </div>
                     </div>
                     <div v-if="rows.ans==='big' && rows.number==='9'">
                        <div class="bet__status_success">
                           <p>Success</p>
                           <p style="margin-top:-10px">₹{{ Number(2*(rows.amount- rows.amount*5/100)).toFixed(2) }}</p>
                        </div>
                     </div>
                     <div v-if="rows.ans==='0' || rows.ans==='1' || rows.ans==='2' || rows.ans==='3' || rows.ans==='4' || rows.ans==='5' || rows.ans==='6' || rows.ans==='7' || rows.ans==='8' || rows.ans==='9' ">
                        <div class="bet__status_success">
                           <p>Success</p>
                           <p style="margin-top:-10px">₹{{ Number(9*(rows.amount- rows.amount*5/100)).toFixed(2) }}</p>
                        </div>
                     </div>
                  </div>
                  <div v-if="rows.res === 'fail'">
                     <div class="bet__status_lose">
                        <p>Failed</p>
                        <p style="margin-top:-10px">-₹{{ Number(rows.amount- rows.amount*5/100).toFixed(2) }}</p>
                     </div>
                  </div>
               </div>
               <div v-show="openIndex === rows.id" class="collapse-content">
                  <div data-v-2faec5cb="" class="MyGameRecordList__C-detail">
                     <div data-v-2faec5cb="" class="MyGameRecordList__C-detail-text">Details</div>
                     <div data-v-2faec5cb="" class="MyGameRecordList__C-detail-line">
                        <span data-v-2faec5cb="">Period</span>
                        <div data-v-2faec5cb="">{{ rows.period }}</div>
                     </div>
                     <div data-v-2faec5cb="" class="MyGameRecordList__C-detail-line">
                        <span data-v-2faec5cb="">Purchase amount</span>
                        <div data-v-2faec5cb="">₹{{ Number(rows.amount).toFixed(2) }}</div>
                     </div>
                     <div data-v-2faec5cb="" class="MyGameRecordList__C-detail-line">
                        <span data-v-2faec5cb="">Quantity</span>
                        <div data-v-2faec5cb="">1</div>
                     </div>
                     <div data-v-2faec5cb="" class="MyGameRecordList__C-detail-line">
                        <span data-v-2faec5cb="">Amount after tax</span>
                        <div data-v-2faec5cb="" class="red">₹{{ Number(rows.amount-rows.amount*5/100).toFixed(2) }}</div>
                     </div>
                     <div data-v-2faec5cb="" class="MyGameRecordList__C-detail-line">
                        <span data-v-2faec5cb="">Tax</span>
                        <div data-v-2faec5cb="">₹{{ Number(rows.amount*5/100).toFixed(2) }}</div>
                     </div>
                     <div data-v-2faec5cb="" class="MyGameRecordList__C-detail-line">
                        <span data-v-2faec5cb="">Result</span>
                        <div data-v-2faec5cb="">
                           <div data-v-2faec5cb="" class="MyGameRecordList__C-inlineB">{{ rows.number }}</div>
                           <div data-v-2faec5cb="" class="MyGameRecordList__C-inlineB greenColor text-capitalize">{{ rows.color }}</div>
                           <!--v-if-->
                           <div data-v-2faec5cb="" class="MyGameRecordList__C-inlineB" v-if="rows.number==='5' || rows.number==='6' || rows.number==='7' || rows.number==='8' || rows.number==='9'">Big</div>
                           <div data-v-2faec5cb="" class="MyGameRecordList__C-inlineB" v-if="rows.number==='0' || rows.number==='1' || rows.number==='2' || rows.number==='3' || rows.number==='4'">Small</div>
                        </div>
                     </div>
                     <div data-v-2faec5cb="" class="MyGameRecordList__C-detail-line">
                        <span data-v-2faec5cb="">Select</span>
                        <div data-v-2faec5cb="" class="text-capitalize">{{ rows.ans }}</div>
                     </div>
                     <div data-v-2faec5cb="" class="MyGameRecordList__C-detail-line">
                        <span data-v-2faec5cb="">Status</span>
                        <div data-v-2faec5cb="" class="red" v-if="rows.res==='fail'">Failed</div>
                        <div data-v-2faec5cb="" class="green" v-if="rows.res==='success'">Success</div>
                     </div>
                     <div data-v-2faec5cb="" class="MyGameRecordList__C-detail-line">
                        <span data-v-2faec5cb="">Win/lose</span>
                        <div data-v-2faec5cb="" class="red" v-if="rows.res==='fail'">- ₹{{ Number(rows.amount-rows.amount*5/100).toFixed(2) }}</div>
                        <div data-v-2faec5cb="" class="green" v-if="rows.res==='success' && rows.number==='0' && rows.ans==='violet'"> ₹{{ Number(4*(rows.amount-rows.amount*5/100).toFixed(2)) }}</div>
                        <div data-v-2faec5cb="" class="green" v-if="rows.res==='success' && rows.number==='5' && rows.ans==='violet'"> ₹{{ Number(4*(rows.amount-rows.amount*5/100).toFixed(2)) }}</div>
                        <div data-v-2faec5cb="" class="green" v-if="rows.res==='success' && rows.number==='1' && rows.ans==='green'"> ₹{{ Number(2*(rows.amount-rows.amount*5/100).toFixed(2)) }}</div>
                        <div data-v-2faec5cb="" class="green" v-if="rows.res==='success' && rows.number==='3' && rows.ans==='green'"> ₹{{ Number(2*(rows.amount-rows.amount*5/100).toFixed(2)) }}</div>
                        <div data-v-2faec5cb="" class="green" v-if="rows.res==='success' && rows.number==='5' && rows.ans==='green'"> ₹{{ Number(1.5*(rows.amount-rows.amount*5/100).toFixed(2)) }}</div>
                        <div data-v-2faec5cb="" class="green" v-if="rows.res==='success' && rows.number==='7' && rows.ans==='green'"> ₹{{ Number(2*(rows.amount-rows.amount*5/100).toFixed(2)) }}</div>
                        <div data-v-2faec5cb="" class="green" v-if="rows.res==='success' && rows.number==='9' && rows.ans==='green'"> ₹{{ Number(2*(rows.amount-rows.amount*5/100).toFixed(2)) }}</div>
                        <div data-v-2faec5cb="" class="green" v-if="rows.res==='success' && rows.number==='0' && rows.ans==='red'"> ₹{{ Number(1.5*(rows.amount-rows.amount*5/100).toFixed(2)) }}</div>
                        <div data-v-2faec5cb="" class="green" v-if="rows.res==='success' && rows.number==='0' && rows.ans==='small'"> ₹{{ Number(2*(rows.amount-rows.amount*5/100).toFixed(2)) }}</div>
                        <div data-v-2faec5cb="" class="green" v-if="rows.res==='success' && rows.number==='1' && rows.ans==='small'"> ₹{{ Number(2*(rows.amount-rows.amount*5/100).toFixed(2)) }}</div>
                        <div data-v-2faec5cb="" class="green" v-if="rows.res==='success' && rows.number==='2' && rows.ans==='small'"> ₹{{ Number(2*(rows.amount-rows.amount*5/100).toFixed(2)) }}</div>
                        <div data-v-2faec5cb="" class="green" v-if="rows.res==='success' && rows.number==='3' && rows.ans==='small'"> ₹{{ Number(2*(rows.amount-rows.amount*5/100).toFixed(2)) }}</div>
                        <div data-v-2faec5cb="" class="green" v-if="rows.res==='success' && rows.number==='4' && rows.ans==='small'"> ₹{{ Number(2*(rows.amount-rows.amount*5/100).toFixed(2)) }}</div>
                        <div data-v-2faec5cb="" class="green" v-if="rows.res==='success' && rows.number==='5' && rows.ans==='big'"> ₹{{ Number(2*(rows.amount-rows.amount*5/100).toFixed(2)) }}</div>
                        <div data-v-2faec5cb="" class="green" v-if="rows.res==='success' && rows.number==='6' && rows.ans==='big'"> ₹{{ Number(2*(rows.amount-rows.amount*5/100).toFixed(2)) }}</div>
                        <div data-v-2faec5cb="" class="green" v-if="rows.res==='success' && rows.number==='7' && rows.ans==='big'"> ₹{{ Number(2*(rows.amount-rows.amount*5/100).toFixed(2)) }}</div>
                        <div data-v-2faec5cb="" class="green" v-if="rows.res==='success' && rows.number==='8' && rows.ans==='big'"> ₹{{ Number(2*(rows.amount-rows.amount*5/100).toFixed(2)) }}</div>
                        <div data-v-2faec5cb="" class="green" v-if="rows.res==='success' && rows.number==='9' && rows.ans==='big'"> ₹{{ Number(2*(rows.amount-rows.amount*5/100).toFixed(2)) }}</div>
                        <div data-v-2faec5cb="" class="green" v-if="rows.res==='success' && rows.ans==='0'"> ₹{{ Number(9*(rows.amount-rows.amount*5/100).toFixed(2)) }}</div>
                        <div data-v-2faec5cb="" class="green" v-if="rows.res==='success' && rows.ans==='1'"> ₹{{ Number(9*(rows.amount-rows.amount*5/100).toFixed(2)) }}</div>
                        <div data-v-2faec5cb="" class="green" v-if="rows.res==='success' && rows.ans==='2'"> ₹{{ Number(9*(rows.amount-rows.amount*5/100).toFixed(2)) }}</div>
                        <div data-v-2faec5cb="" class="green" v-if="rows.res==='success' && rows.ans==='3'"> ₹{{ Number(9*(rows.amount-rows.amount*5/100).toFixed(2)) }}</div>
                        <div data-v-2faec5cb="" class="green" v-if="rows.res==='success' && rows.ans==='4'"> ₹{{ Number(9*(rows.amount-rows.amount*5/100).toFixed(2)) }}</div>
                        <div data-v-2faec5cb="" class="green" v-if="rows.res==='success' && rows.ans==='5'"> ₹{{ Number(9*(rows.amount-rows.amount*5/100).toFixed(2)) }}</div>
                        <div data-v-2faec5cb="" class="green" v-if="rows.res==='success' && rows.ans==='6'"> ₹{{ Number(9*(rows.amount-rows.amount*5/100).toFixed(2)) }}</div>
                        <div data-v-2faec5cb="" class="green" v-if="rows.res==='success' && rows.ans==='7'"> ₹{{ Number(9*(rows.amount-rows.amount*5/100).toFixed(2)) }}</div>
                        <div data-v-2faec5cb="" class="green" v-if="rows.res==='success' && rows.ans==='8'"> ₹{{ Number(9*(rows.amount-rows.amount*5/100).toFixed(2)) }}</div>
                        <div data-v-2faec5cb="" class="green" v-if="rows.res==='success' && rows.ans==='9'"> ₹{{ Number(9*(rows.amount-rows.amount*5/100).toFixed(2)) }}</div>
                     </div>
                     <div data-v-2faec5cb="" class="MyGameRecordList__C-detail-line">
                        <span data-v-2faec5cb="">Order time</span>
                        <div data-v-2faec5cb="">{{ rows.time }}</div>
                     </div>
                  </div>
               </div>
            </div>
            </div>
            <div class="row">
               <div data-v-481307ec="" class="GameRecord__C-foot">
                  <div v-if="betbox.page1===1">
                     <div data-v-481307ec="" class="GameRecord__C-foot-previous disabled">
                        <i data-v-481307ec="" @click="down1()" class="van-badge__wrapper van-icon van-icon-arrow-left GameRecord__C-icon fa fa-angle-left" style="font-size: 20px;">
                        <!----><!----><!---->
                        </i>
                     </div>
                  </div>
                  <div v-if="betbox.page1>1">
                     <div data-v-481307ec="" class="GameRecord__C-foot-previous">
                        <i data-v-481307ec="" @click="down1()" class="van-badge__wrapper van-icon van-icon-arrow-left GameRecord__C-icon fa fa-angle-left" style="font-size: 20px;">
                        <!----><!----><!---->
                        </i>
                     </div>
                  </div>
                  <div data-v-481307ec="" class="GameRecord__C-foot-page">{{(betbox.page1)*1}}/{{Math.round(betbox.total1/10)}}</div>
                     <div v-if="betbox.page1 < Math.round(betbox.total1/10)">
                        <div data-v-481307ec="" class="GameRecord__C-foot-next">
                        <i data-v-481307ec="" @click="up1()" class="van-badge__wrapper van-icon van-icon-arrow GameRecord__C-icon fa fa-angle-right" style="font-size: 20px;">
                           <!----><!----><!---->
                        </i>
                        </div>
                     </div>
                     <div v-if="betbox.page1 === Math.round(betbox.total1/10) || Math.round(betbox.total1/10)=== 0">
                        <div data-v-481307ec="" class="GameRecord__C-foot-next disabled">
                        <i data-v-481307ec="" @click="up1()" class="van-badge__wrapper van-icon van-icon-arrow GameRecord__C-icon fa fa-angle-right" style="font-size: 20px;">
                           <!----><!----><!---->
                        </i>
                        </div>
                     </div>
                  </div>
            </div>
         </div>
      </div>
      <br>
   </div>
   <!--How to play Modal -->
   <div class="modal fade" id="exampleModalLong" tabindex="-1" role="dialog" aria-labelledby="exampleModalLongTitle" aria-hidden="true">
      <div class="modal-dialog" role="document">
         <div class="modal-content">
            <div class="modal-header" style="background: var(--main-color);">
               <center>
                  <h5 class="text-white" id="exampleModalLongTitle">How to play</h5>
               </center>
            </div>
            <div class="modal-body model-text">
               <p>In the Win go game, each period will produce 1 result from 0 to 9.</p>
               <p>There are four period intervals to choose from: every 1 min/3 mins/5 mins/10 mins. The gameplay is the same.</p>
               <p>There are 3 ways to play: 1.Guessing the color, 2.Guessing the range, 3.Guessing Exact Number</p>
               <p>1.Guessing the color</p>
               <p>If result is one of 1,3,7,9 then【Green】win, odds 1:1</p>
               <p>If result is one of 2,4,6,8 then【Red】win, odds 1:1</p>
               <p>If result is 0 or 5 then【Violet】win, odds 1:1</p>
               <p>If you choose【green】and result is【5】 still win ! odds 1:0.5</p>
               <p>If you choose【Red 】and result is【0】still win ! odds 1:0.5</p>
               <p>If result is 0 or 5 then【Violet】win, odds 1:1</p>
            </div>
            <div class="modal-footer" style="justify-content: center;">
               <button type="button" class="btn text-white" style="background-color: var(--main-color);" data-dismiss="modal">Done</button>
            </div>
         </div>
      </div>
   </div>
   <!-- modal end -->
   <!--green modal start -->
   <div class="modal fade" id="green" tabindex="-1" role="dialog" aria-labelledby="exampleModalLongTitle" aria-hidden="true">
      <div class="modal-dialog" role="document">
         <form> 
            <div class="modal-content">
            <div class="green_pop__head_design">
               <h3 class="Betting__Popup-head-title">Win Go 1Min</h3>
               <div class="selected-color">
                  <p>Select &nbsp; &nbsp;&nbsp;&nbsp; Green</p>  
               </div>
            </div>
            <div class="model__body__content">
               <p>Balance</p>
               <div :class="['r1', { active_green: activeFactor === 1 }]" @click="multiply(1)">1</div>
               <div :class="['r2', { active_green: activeFactor === 10 }]" @click="multiply(10)">10</div>
               <div :class="['r3', { active_green: activeFactor === 100 }]" @click="multiply(100)">100</div>
               <div :class="['r4', { active_green: activeFactor === 1000 }]" @click="multiply(1000)">1000</div>
            </div>
            <div class="model__body__content" style="margin-top:0">
               <p>Quantity</p>
               <div class="r1 active-1" @click="decrement">-</div>
               <input type="number" value="1" v-model="value" class="quantity__input" @input="updateMultipliedValue">
               <div class="r1 active-10" @click="increment">+</div>
            </div>
            <div class="model__body__content" style="margin-top:0">
               <div v-if="value === 1" class="green_1x active_green" @click="updateInput(1)">X1</div>
               <div v-else class="green_1x" @click="updateInput(1)">X1</div>
               <div v-if="value === 5" class="green_5x active_green" @click="updateInput(5)">X5</div>
               <div v-else class="green_5x" @click="updateInput(5)">X5</div>
               <div v-if="value === 10" class="green_10x active_green" @click="updateInput(10)">X10</div>
               <div v-else class="green_10x" @click="updateInput(10)">X10</div>
               <div v-if="value === 20" class="green_10x active_green" @click="updateInput(20)">X20</div>
               <div v-else class="green_10x" @click="updateInput(20)">X20</div>
               <div v-if="value === 50" class="green_50x active_green" @click="updateInput(50)">X50</div>
               <div v-else class="green_50x" @click="updateInput(50)">X50</div>
               <div v-if="value === 100" class="green_100x active_green" @click="updateInput(100)">X100</div>
               <div v-else class="green_100x" @click="updateInput(100)">X100</div>
            </div>
            <div class="model__body__content" style="margin-top:0; margin-bottom: 10px">
               <img src="../assets/png/agree-a-95c84913.png" alt="" width="20px" height="20px">
               <p class="pl-1">I agree</p>
               <p class="pl-1" style="color:var(--norm_red-color)">《Pre-sale rules》</p>
            </div>
            <div class="greenModel__footer">
               <div class="cancel" @click="reload()" data-dismiss="modal">Cancel</div>
               <div class="order__place" @click="confirmbetgreen()" data-dismiss="modal">Total Amount ₹{{ multipliedValue }}.00</div>
            </div>
         </div>
      </form>
      </div>
   </div>
   <!--green modal end -->
   <!--red modal start -->
   <div class="modal fade" id="red" tabindex="-1" role="dialog" aria-labelledby="exampleModalLongTitle" aria-hidden="true">
      <div class="modal-dialog" role="document">
         <div class="modal-content">
            <div class="red_pop__head_design">
               <h3 class="Betting__Popup-head-title">Win Go 1Min</h3>
               <div class="selected-color">
                  <p>Select &nbsp; &nbsp;&nbsp;&nbsp; Red</p>  
               </div>
            </div>
            <div class="model__body__content">
               <p>Balance</p>
               <div :class="['r1', { active_red: activeFactor === 1 }]" @click="multiply(1)">1</div>
               <div :class="['r2', { active_red: activeFactor === 10 }]" @click="multiply(10)">10</div>
               <div :class="['r3', { active_red: activeFactor === 100 }]" @click="multiply(100)">100</div>
               <div :class="['r4', { active_red: activeFactor === 1000 }]" @click="multiply(1000)">1000</div>
            </div>
            <div class="model__body__content" style="margin-top:0">
               <p>Quantity</p>
               <div class="r1 active-1_red" @click="decrement">-</div>
               <input type="number" value="1" v-model="value" class="quantity__input" @input="updateMultipliedValue">
               <div class="r1 active-10_red" @click="increment">+</div>
            </div>
            <div class="model__body__content" style="margin-top:0">
               <div v-if="value === 1" class="red_1x active_red" @click="updateInput(1)">X1</div>
               <div v-else class="red_1x" @click="updateInput(1)">X1</div>
               <div v-if="value === 5" class="red_5x active_red" @click="updateInput(5)">X5</div>
               <div v-else class="red_5x" @click="updateInput(5)">X5</div>
               <div v-if="value === 10" class="red_5x active_red" @click="updateInput(10)">X10</div>
               <div v-else class="red_10x" @click="updateInput(10)">X10</div>
               <div v-if="value === 20" class="red_10x active_red" @click="updateInput(20)">X20</div>
               <div v-else class="red_10x" @click="updateInput(20)">X20</div>
               <div v-if="value === 50" class="red_50x active_red" @click="updateInput(50)">X50</div>
               <div v-else class="red_50x" @click="updateInput(50)">X50</div>
               <div v-if="value === 100" class="red_100x active_red" @click="updateInput(100)">X100</div>
               <div v-else class="red_100x" @click="updateInput(100)">X100</div>
            </div>
            <div class="model__body__content" style="margin-top:0; margin-bottom: 10px">
               <img src="../assets/png/agree-a-95c84913.png" alt="" width="20px" height="20px">
               <p class="pl-1">I agree</p>
               <p class="pl-1" style="color:var(--norm_red-color)">《Pre-sale rules》</p>
            </div>
            <div class="redModel__footer">
               <div class="cancel" @click="reload()" data-dismiss="modal">Cancel</div>
               <div class="order__place" @click="confirmbetred()" data-dismiss="modal">Total Amount ₹{{ multipliedValue }}.00</div>
            </div>
         </div>
      </div>
   </div>
   <!--red modal end -->
   <!--violet modal start -->
   <div class="modal fade" id="violet" tabindex="-1" role="dialog" aria-labelledby="exampleModalLongTitle" aria-hidden="true">
      <div class="modal-dialog" role="document">
         <div class="modal-content">
            <div class="violet_pop__head_design">
               <h3 class="Betting__Popup-head-title">Win Go 1Min</h3>
               <div class="selected-color">
                  <p>Select &nbsp; &nbsp;&nbsp;&nbsp; Violet</p>  
               </div>
            </div>
            <div class="model__body__content">
               <p>Balance</p>
               <div :class="['r1', { active_violet: activeFactor === 1 }]" @click="multiply(1)">1</div>
               <div :class="['r2', { active_violet: activeFactor === 10 }]" @click="multiply(10)">10</div>
               <div :class="['r3', { active_violet: activeFactor === 100 }]" @click="multiply(100)">100</div>
               <div :class="['r4', { active_violet: activeFactor === 1000 }]" @click="multiply(1000)">1000</div>
            </div>
            <div class="model__body__content" style="margin-top:0">
               <p>Quantity</p>
               <div class="r1 active-1_violet" @click="decrement">-</div>
               <input type="number" value="1" v-model="value" class="quantity__input" @input="updateMultipliedValue">
               <div class="r1 active-10_violet" @click="increment">+</div>
            </div>
            <div class="model__body__content" style="margin-top:0">
               <div v-if="value === 1" class="red_1x active_violet" @click="updateInput(1)">X1</div>
               <div v-else class="red_1x" @click="updateInput(1)">X1</div>
               <div v-if="value === 5" class="red_5x active_violet" @click="updateInput(5)">X5</div>
               <div v-else class="red_5x" @click="updateInput(5)">X5</div>
               <div v-if="value === 10" class="red_5x active_violet" @click="updateInput(10)">X10</div>
               <div v-else class="red_10x" @click="updateInput(10)">X10</div>
               <div v-if="value === 20" class="red_10x active_violet" @click="updateInput(20)">X20</div>
               <div v-else class="red_10x" @click="updateInput(20)">X20</div>
               <div v-if="value === 50" class="red_50x active_violet" @click="updateInput(50)">X50</div>
               <div v-else class="red_50x" @click="updateInput(50)">X50</div>
               <div v-if="value === 100" class="red_100x active_violet" @click="updateInput(100)">X100</div>
               <div v-else class="red_100x" @click="updateInput(100)">X100</div>
            </div>
            <div class="model__body__content" style="margin-top:0; margin-bottom: 10px">
               <img src="../assets/png/agree-a-95c84913.png" alt="" width="20px" height="20px">
               <p class="pl-1">I agree</p>
               <p class="pl-1" style="color:var(--norm_red-color)">《Pre-sale rules》</p>
            </div>
            <div class="violetModel__footer">
               <div class="cancel" @click="reload()" data-dismiss="modal">Cancel</div>
               <div class="order__place" @click="confirmbetviolet()" data-dismiss="modal">Total Amount ₹{{ multipliedValue }}.00</div>
            </div>
         </div>
      </div>
   </div>
   <!--violet modal end -->
   <!--0 modal start -->
   <div class="modal fade" id="zero" tabindex="-1" role="dialog" aria-labelledby="exampleModalLongTitle" aria-hidden="true">
      <div class="modal-dialog" role="document">
         <div class="modal-content">
            <div class="violet_pop__head_design">
               <h3 class="Betting__Popup-head-title">Win Go 1Min</h3>
               <div class="selected-color">
                  <p>Select &nbsp; &nbsp;&nbsp;&nbsp; 0</p>  
               </div>
            </div>
            <div class="model__body__content">
               <p>Balance</p>
               <div :class="['r1', { active_violet: activeFactor === 1 }]" @click="multiply(1)">1</div>
               <div :class="['r2', { active_violet: activeFactor === 10 }]" @click="multiply(10)">10</div>
               <div :class="['r3', { active_violet: activeFactor === 100 }]" @click="multiply(100)">100</div>
               <div :class="['r4', { active_violet: activeFactor === 1000 }]" @click="multiply(1000)">1000</div>
            </div>
            <div class="model__body__content" style="margin-top:0">
               <p>Quantity</p>
               <div class="r1 active-1_violet" @click="decrement">-</div>
               <input type="number" value="1" v-model="value" class="quantity__input" @input="updateMultipliedValue">
               <div class="r1 active-10_violet" @click="increment">+</div>
            </div>
            <div class="model__body__content" style="margin-top:0">
               <div v-if="value === 1" class="red_1x active_violet" @click="updateInput(1)">X1</div>
               <div v-else class="red_1x" @click="updateInput(1)">X1</div>
               <div v-if="value === 5" class="red_5x active_violet" @click="updateInput(5)">X5</div>
               <div v-else class="red_5x" @click="updateInput(5)">X5</div>
               <div v-if="value === 10" class="red_5x active_violet" @click="updateInput(10)">X10</div>
               <div v-else class="red_10x" @click="updateInput(10)">X10</div>
               <div v-if="value === 20" class="red_10x active_violet" @click="updateInput(20)">X20</div>
               <div v-else class="red_10x" @click="updateInput(20)">X20</div>
               <div v-if="value === 50" class="red_50x active_violet" @click="updateInput(50)">X50</div>
               <div v-else class="red_50x" @click="updateInput(50)">X50</div>
               <div v-if="value === 100" class="red_100x active_violet" @click="updateInput(100)">X100</div>
               <div v-else class="red_100x" @click="updateInput(100)">X100</div>
            </div>
            <div class="model__body__content" style="margin-top:0; margin-bottom: 10px">
               <img src="../assets/png/agree-a-95c84913.png" alt="" width="20px" height="20px">
               <p class="pl-1">I agree</p>
               <p class="pl-1" style="color:var(--norm_red-color)">《Pre-sale rules》</p>
            </div>
            <div class="violetModel__footer">
               <div class="cancel" @click="reload()" data-dismiss="modal">Cancel</div>
               <div class="order__place" @click="confirmbet0()" data-dismiss="modal">Total Amount ₹{{ multipliedValue }}.00</div>
            </div>
         </div>
      </div>
   </div>
   <!--0 modal end -->
   <!--one modal start -->
   <div class="modal fade" id="one" tabindex="-1" role="dialog" aria-labelledby="exampleModalLongTitle" aria-hidden="true">
      <div class="modal-dialog" role="document">
         <div class="modal-content">
            <div class="green_pop__head_design">
               <h3 class="Betting__Popup-head-title">Win Go 1Min</h3>
               <div class="selected-color">
                  <p>Select &nbsp; &nbsp;&nbsp;&nbsp; 1</p>  
               </div>
            </div>
            <div class="model__body__content">
               <p>Balance</p>
               <div :class="['r1', { active_green: activeFactor === 1 }]" @click="multiply(1)">1</div>
               <div :class="['r2', { active_green: activeFactor === 10 }]" @click="multiply(10)">10</div>
               <div :class="['r3', { active_green: activeFactor === 100 }]" @click="multiply(100)">100</div>
               <div :class="['r4', { active_green: activeFactor === 1000 }]" @click="multiply(1000)">1000</div>
            </div>
            <div class="model__body__content" style="margin-top:0">
               <p>Quantity</p>
               <div class="r1 active-1" @click="decrement">-</div>
               <input type="number" value="1" v-model="value" class="quantity__input" @input="updateMultipliedValue">
               <div class="r1 active-10" @click="increment">+</div>
            </div>
            <div class="model__body__content" style="margin-top:0">
               <div v-if="value === 1" class="red_1x active_green" @click="updateInput(1)">X1</div>
               <div v-else class="red_1x" @click="updateInput(1)">X1</div>
               <div v-if="value === 5" class="red_5x active_green" @click="updateInput(5)">X5</div>
               <div v-else class="red_5x" @click="updateInput(5)">X5</div>
               <div v-if="value === 10" class="red_5x active_green" @click="updateInput(10)">X10</div>
               <div v-else class="red_10x" @click="updateInput(10)">X10</div>
               <div v-if="value === 20" class="red_10x active_green" @click="updateInput(20)">X20</div>
               <div v-else class="red_10x" @click="updateInput(20)">X20</div>
               <div v-if="value === 50" class="red_50x active_green" @click="updateInput(50)">X50</div>
               <div v-else class="red_50x" @click="updateInput(50)">X50</div>
               <div v-if="value === 100" class="red_100x active_green" @click="updateInput(100)">X100</div>
               <div v-else class="red_100x" @click="updateInput(100)">X100</div>
            </div>
            <div class="model__body__content" style="margin-top:0; margin-bottom: 10px">
               <img src="../assets/png/agree-a-95c84913.png" alt="" width="20px" height="20px">
               <p class="pl-1">I agree</p>
               <p class="pl-1" style="color:var(--norm_red-color)">《Pre-sale rules》</p>
            </div>
            <div class="greenModel__footer">
               <div class="cancel" @click="reload()" data-dismiss="modal">Cancel</div>
               <div class="order__place" @click="confirmbet1()" data-dismiss="modal">Total Amount ₹{{ multipliedValue }}.00</div>
            </div>
         </div>
      </div>
   </div>
   <!--one modal end -->
   <!--2 modal start -->
   <div class="modal fade" id="two" tabindex="-1" role="dialog" aria-labelledby="exampleModalLongTitle" aria-hidden="true">
      <div class="modal-dialog" role="document">
         <div class="modal-content">
            <div class="red_pop__head_design">
               <h3 class="Betting__Popup-head-title">Win Go 1Min</h3>
               <div class="selected-color">
                  <p>Select &nbsp; &nbsp;&nbsp;&nbsp; 2</p>  
               </div>
            </div>
            <div class="model__body__content">
               <p>Balance</p>
               <div :class="['r1', { active_red: activeFactor === 1 }]" @click="multiply(1)">1</div>
               <div :class="['r2', { active_red: activeFactor === 10 }]" @click="multiply(10)">10</div>
               <div :class="['r3', { active_red: activeFactor === 100 }]" @click="multiply(100)">100</div>
               <div :class="['r4', { active_red: activeFactor === 1000 }]" @click="multiply(1000)">1000</div>
            </div>
            <div class="model__body__content" style="margin-top:0">
               <p>Quantity</p>
               <div class="r1 active-1_red" @click="decrement">-</div>
               <input type="number" value="1" v-model="value" class="quantity__input" @input="updateMultipliedValue">
               <div class="r1 active-10_red" @click="increment">+</div>
            </div>
            <div class="model__body__content" style="margin-top:0">
               <div v-if="value === 1" class="red_1x active_red" @click="updateInput(1)">X1</div>
               <div v-else class="red_1x" @click="updateInput(1)">X1</div>
               <div v-if="value === 5" class="red_5x active_red" @click="updateInput(5)">X5</div>
               <div v-else class="red_5x" @click="updateInput(5)">X5</div>
               <div v-if="value === 10" class="red_5x active_red" @click="updateInput(10)">X10</div>
               <div v-else class="red_10x" @click="updateInput(10)">X10</div>
               <div v-if="value === 20" class="red_10x active_red" @click="updateInput(20)">X20</div>
               <div v-else class="red_10x" @click="updateInput(20)">X20</div>
               <div v-if="value === 50" class="red_50x active_red" @click="updateInput(50)">X50</div>
               <div v-else class="red_50x" @click="updateInput(50)">X50</div>
               <div v-if="value === 100" class="red_100x active_red" @click="updateInput(100)">X100</div>
               <div v-else class="red_100x" @click="updateInput(100)">X100</div>
            </div>
            <div class="model__body__content" style="margin-top:0; margin-bottom: 10px">
               <img src="../assets/png/agree-a-95c84913.png" alt="" width="20px" height="20px">
               <p class="pl-1">I agree</p>
               <p class="pl-1" style="color:var(--norm_red-color)">《Pre-sale rules》</p>
            </div>
            <div class="redModel__footer">
               <div class="cancel" @click="reload()" data-dismiss="modal">Cancel</div>
               <div class="order__place" @click="confirmbet2()" data-dismiss="modal">Total Amount ₹{{ multipliedValue }}.00</div>
            </div>
         </div>
      </div>
   </div>
   <!--2 modal end -->
   <!--three modal start -->
   <div class="modal fade" id="three" tabindex="-1" role="dialog" aria-labelledby="exampleModalLongTitle" aria-hidden="true">
      <div class="modal-dialog" role="document">
         <div class="modal-content">
            <div class="green_pop__head_design">
               <h3 class="Betting__Popup-head-title">Win Go 1Min</h3>
               <div class="selected-color">
                  <p>Select &nbsp; &nbsp;&nbsp;&nbsp; 3</p>  
               </div>
            </div>
            <div class="model__body__content">
               <p>Balance</p>
               <div :class="['r1', { active_green: activeFactor === 1 }]" @click="multiply(1)">1</div>
               <div :class="['r2', { active_green: activeFactor === 10 }]" @click="multiply(10)">10</div>
               <div :class="['r3', { active_green: activeFactor === 100 }]" @click="multiply(100)">100</div>
               <div :class="['r4', { active_green: activeFactor === 1000 }]" @click="multiply(1000)">1000</div>
            </div>
            <div class="model__body__content" style="margin-top:0">
               <p>Quantity</p>
               <div class="r1 active-1" @click="decrement">-</div>
               <input type="number" value="1" v-model="value" class="quantity__input" @input="updateMultipliedValue">
               <div class="r1 active-10" @click="increment">+</div>
            </div>
            <div class="model__body__content" style="margin-top:0">
               <div v-if="value === 1" class="red_1x active_green" @click="updateInput(1)">X1</div>
               <div v-else class="red_1x" @click="updateInput(1)">X1</div>
               <div v-if="value === 5" class="red_5x active_green" @click="updateInput(5)">X5</div>
               <div v-else class="red_5x" @click="updateInput(5)">X5</div>
               <div v-if="value === 10" class="red_5x active_green" @click="updateInput(10)">X10</div>
               <div v-else class="red_10x" @click="updateInput(10)">X10</div>
               <div v-if="value === 20" class="red_10x active_green" @click="updateInput(20)">X20</div>
               <div v-else class="red_10x" @click="updateInput(20)">X20</div>
               <div v-if="value === 50" class="red_50x active_green" @click="updateInput(50)">X50</div>
               <div v-else class="red_50x" @click="updateInput(50)">X50</div>
               <div v-if="value === 100" class="red_100x active_green" @click="updateInput(100)">X100</div>
               <div v-else class="red_100x" @click="updateInput(100)">X100</div>
            </div>
            <div class="model__body__content" style="margin-top:0; margin-bottom: 10px">
               <img src="../assets/png/agree-a-95c84913.png" alt="" width="20px" height="20px">
               <p class="pl-1">I agree</p>
               <p class="pl-1" style="color:var(--norm_red-color)">《Pre-sale rules》</p>
            </div>
            <div class="greenModel__footer">
               <div class="cancel" @click="reload()" data-dismiss="modal">Cancel</div>
               <div class="order__place" @click="confirmbet3()" data-dismiss="modal">Total Amount ₹{{ multipliedValue }}.00</div>
            </div>
         </div>
      </div>
   </div>
   <!--three modal end -->
   <!--four modal start -->
   <div class="modal fade" id="four" tabindex="-1" role="dialog" aria-labelledby="exampleModalLongTitle" aria-hidden="true">
      <div class="modal-dialog" role="document">
         <div class="modal-content">
            <div class="red_pop__head_design">
               <h3 class="Betting__Popup-head-title">Win Go 1Min</h3>
               <div class="selected-color">
                  <p>Select &nbsp; &nbsp;&nbsp;&nbsp; 4</p>  
               </div>
            </div>
            <div class="model__body__content">
               <p>Balance</p>
               <div :class="['r1', { active_red: activeFactor === 1 }]" @click="multiply(1)">1</div>
               <div :class="['r2', { active_red: activeFactor === 10 }]" @click="multiply(10)">10</div>
               <div :class="['r3', { active_red: activeFactor === 100 }]" @click="multiply(100)">100</div>
               <div :class="['r4', { active_red: activeFactor === 1000 }]" @click="multiply(1000)">1000</div>
            </div>
            <div class="model__body__content" style="margin-top:0">
               <p>Quantity</p>
               <div class="r1 active-1_red" @click="decrement">-</div>
               <input type="number" value="1" v-model="value" class="quantity__input" @input="updateMultipliedValue">
               <div class="r1 active-10_red" @click="increment">+</div>
            </div>
            <div class="model__body__content" style="margin-top:0">
               <div v-if="value === 1" class="red_1x active_red" @click="updateInput(1)">X1</div>
               <div v-else class="red_1x" @click="updateInput(1)">X1</div>
               <div v-if="value === 5" class="red_5x active_red" @click="updateInput(5)">X5</div>
               <div v-else class="red_5x" @click="updateInput(5)">X5</div>
               <div v-if="value === 10" class="red_5x active_red" @click="updateInput(10)">X10</div>
               <div v-else class="red_10x" @click="updateInput(10)">X10</div>
               <div v-if="value === 20" class="red_10x active_red" @click="updateInput(20)">X20</div>
               <div v-else class="red_10x" @click="updateInput(20)">X20</div>
               <div v-if="value === 50" class="red_50x active_red" @click="updateInput(50)">X50</div>
               <div v-else class="red_50x" @click="updateInput(50)">X50</div>
               <div v-if="value === 100" class="red_100x active_red" @click="updateInput(100)">X100</div>
               <div v-else class="red_100x" @click="updateInput(100)">X100</div>
            </div>
            <div class="model__body__content" style="margin-top:0; margin-bottom: 10px">
               <img src="../assets/png/agree-a-95c84913.png" alt="" width="20px" height="20px">
               <p class="pl-1">I agree</p>
               <p class="pl-1" style="color:var(--norm_red-color)">《Pre-sale rules》</p>
            </div>
            <div class="redModel__footer">
               <div class="cancel" @click="reload()" data-dismiss="modal">Cancel</div>
               <div class="order__place" @click="confirmbet4()" data-dismiss="modal">Total Amount ₹{{ multipliedValue }}.00</div>
            </div>
         </div>
      </div>
   </div>
   <!--four modal end -->
   <!--five modal start -->
   <div class="modal fade" id="five" tabindex="-1" role="dialog" aria-labelledby="exampleModalLongTitle" aria-hidden="true">
      <div class="modal-dialog" role="document">
         <div class="modal-content">
            <div class="violet_pop__head_design">
               <h3 class="Betting__Popup-head-title">Win Go 1Min</h3>
               <div class="selected-color">
                  <p>Select &nbsp; &nbsp;&nbsp;&nbsp; 5</p>  
               </div>
            </div>
            <div class="model__body__content">
               <p>Balance</p>
               <div :class="['r1', { active_violet: activeFactor === 1 }]" @click="multiply(1)">1</div>
               <div :class="['r2', { active_violet: activeFactor === 10 }]" @click="multiply(10)">10</div>
               <div :class="['r3', { active_violet: activeFactor === 100 }]" @click="multiply(100)">100</div>
               <div :class="['r4', { active_violet: activeFactor === 1000 }]" @click="multiply(1000)">1000</div>
            </div>
            <div class="model__body__content" style="margin-top:0">
               <p>Quantity</p>
               <div class="r1 active-1_violet" @click="decrement">-</div>
               <input type="number" value="1" v-model="value" class="quantity__input" @input="updateMultipliedValue">
               <div class="r1 active-10_violet" @click="increment">+</div>
            </div>
            <div class="model__body__content" style="margin-top:0">
               <div v-if="value === 1" class="red_1x active_violet" @click="updateInput(1)">X1</div>
               <div v-else class="red_1x" @click="updateInput(1)">X1</div>
               <div v-if="value === 5" class="red_5x active_violet" @click="updateInput(5)">X5</div>
               <div v-else class="red_5x" @click="updateInput(5)">X5</div>
               <div v-if="value === 10" class="red_5x active_violet" @click="updateInput(10)">X10</div>
               <div v-else class="red_10x" @click="updateInput(10)">X10</div>
               <div v-if="value === 20" class="red_10x active_violet" @click="updateInput(20)">X20</div>
               <div v-else class="red_10x" @click="updateInput(20)">X20</div>
               <div v-if="value === 50" class="red_50x active_violet" @click="updateInput(50)">X50</div>
               <div v-else class="red_50x" @click="updateInput(50)">X50</div>
               <div v-if="value === 100" class="red_100x active_violet" @click="updateInput(100)">X100</div>
               <div v-else class="red_100x" @click="updateInput(100)">X100</div>
            </div>
            <div class="model__body__content" style="margin-top:0; margin-bottom: 10px">
               <img src="../assets/png/agree-a-95c84913.png" alt="" width="20px" height="20px">
               <p class="pl-1">I agree</p>
               <p class="pl-1" style="color:var(--norm_red-color)">《Pre-sale rules》</p>
            </div>
            <div class="violetModel__footer">
               <div class="cancel" @click="reload()" data-dismiss="modal">Cancel</div>
               <div class="order__place" @click="confirmbet5()" data-dismiss="modal">Total Amount ₹{{ multipliedValue }}.00</div>
            </div>
         </div>
      </div>
   </div>
   <!--five modal end -->
   <!--six modal start -->
   <div class="modal fade" id="six" tabindex="-1" role="dialog" aria-labelledby="exampleModalLongTitle" aria-hidden="true">
      <div class="modal-dialog" role="document">
         <div class="modal-content">
            <div class="red_pop__head_design">
               <h3 class="Betting__Popup-head-title">Win Go 1Min</h3>
               <div class="selected-color">
                  <p>Select &nbsp; &nbsp;&nbsp;&nbsp; 6</p>  
               </div>
            </div>
            <div class="model__body__content">
               <p>Balance</p>
               <div :class="['r1', { active_red: activeFactor === 1 }]" @click="multiply(1)">1</div>
               <div :class="['r2', { active_red: activeFactor === 10 }]" @click="multiply(10)">10</div>
               <div :class="['r3', { active_red: activeFactor === 100 }]" @click="multiply(100)">100</div>
               <div :class="['r4', { active_red: activeFactor === 1000 }]" @click="multiply(1000)">1000</div>
            </div>
            <div class="model__body__content" style="margin-top:0">
               <p>Quantity</p>
               <div class="r1 active-1_red" @click="decrement">-</div>
               <input type="number" value="1" v-model="value" class="quantity__input" @input="updateMultipliedValue">
               <div class="r1 active-10_red" @click="increment">+</div>
            </div>
            <div class="model__body__content" style="margin-top:0">
               <div v-if="value === 1" class="red_1x active_red" @click="updateInput(1)">X1</div>
               <div v-else class="red_1x" @click="updateInput(1)">X1</div>
               <div v-if="value === 5" class="red_5x active_red" @click="updateInput(5)">X5</div>
               <div v-else class="red_5x" @click="updateInput(5)">X5</div>
               <div v-if="value === 10" class="red_5x active_red" @click="updateInput(10)">X10</div>
               <div v-else class="red_10x" @click="updateInput(10)">X10</div>
               <div v-if="value === 20" class="red_10x active_red" @click="updateInput(20)">X20</div>
               <div v-else class="red_10x" @click="updateInput(20)">X20</div>
               <div v-if="value === 50" class="red_50x active_red" @click="updateInput(50)">X50</div>
               <div v-else class="red_50x" @click="updateInput(50)">X50</div>
               <div v-if="value === 100" class="red_100x active_red" @click="updateInput(100)">X100</div>
               <div v-else class="red_100x" @click="updateInput(100)">X100</div>
            </div>
            <div class="model__body__content" style="margin-top:0; margin-bottom: 10px">
               <img src="../assets/png/agree-a-95c84913.png" alt="" width="20px" height="20px">
               <p class="pl-1">I agree</p>
               <p class="pl-1" style="color:var(--norm_red-color)">《Pre-sale rules》</p>
            </div>
            <div class="redModel__footer">
               <div class="cancel" @click="reload()" data-dismiss="modal">Cancel</div>
               <div class="order__place" @click="confirmbet6()" data-dismiss="modal">Total Amount ₹{{ multipliedValue }}.00</div>
            </div>
         </div>
      </div>
   </div>
   <!--six modal end -->
   <!--seven modal start -->
   <div class="modal fade" id="seven" tabindex="-1" role="dialog" aria-labelledby="exampleModalLongTitle" aria-hidden="true">
      <div class="modal-dialog" role="document">
         <div class="modal-content">
            <div class="green_pop__head_design">
               <h3 class="Betting__Popup-head-title">Win Go 1Min</h3>
               <div class="selected-color">
                  <p>Select &nbsp; &nbsp;&nbsp;&nbsp; 7</p>  
               </div>
            </div>
            <div class="model__body__content">
               <p>Balance</p>
               <div :class="['r1', { active_green: activeFactor === 1 }]" @click="multiply(1)">1</div>
               <div :class="['r2', { active_green: activeFactor === 10 }]" @click="multiply(10)">10</div>
               <div :class="['r3', { active_green: activeFactor === 100 }]" @click="multiply(100)">100</div>
               <div :class="['r4', { active_green: activeFactor === 1000 }]" @click="multiply(1000)">1000</div>
            </div>
            <div class="model__body__content" style="margin-top:0">
               <p>Quantity</p>
               <div class="r1 active-1" @click="decrement">-</div>
               <input type="number" value="1" v-model="value" class="quantity__input" @input="updateMultipliedValue">
               <div class="r1 active-10" @click="increment">+</div>
            </div>
            <div class="model__body__content" style="margin-top:0">
               <div v-if="value === 1" class="red_1x active_green" @click="updateInput(1)">X1</div>
               <div v-else class="red_1x" @click="updateInput(1)">X1</div>
               <div v-if="value === 5" class="red_5x active_green" @click="updateInput(5)">X5</div>
               <div v-else class="red_5x" @click="updateInput(5)">X5</div>
               <div v-if="value === 10" class="red_5x active_green" @click="updateInput(10)">X10</div>
               <div v-else class="red_10x" @click="updateInput(10)">X10</div>
               <div v-if="value === 20" class="red_10x active_green" @click="updateInput(20)">X20</div>
               <div v-else class="red_10x" @click="updateInput(20)">X20</div>
               <div v-if="value === 50" class="red_50x active_green" @click="updateInput(50)">X50</div>
               <div v-else class="red_50x" @click="updateInput(50)">X50</div>
               <div v-if="value === 100" class="red_100x active_green" @click="updateInput(100)">X100</div>
               <div v-else class="red_100x" @click="updateInput(100)">X100</div>
            </div>
            <div class="model__body__content" style="margin-top:0; margin-bottom: 10px">
               <img src="../assets/png/agree-a-95c84913.png" alt="" width="20px" height="20px">
               <p class="pl-1">I agree</p>
               <p class="pl-1" style="color:var(--norm_red-color)">《Pre-sale rules》</p>
            </div>
            <div class="greenModel__footer">
               <div class="cancel" @click="reload()" data-dismiss="modal">Cancel</div>
               <div class="order__place" @click="confirmbet7()" data-dismiss="modal">Total Amount ₹{{ multipliedValue }}.00</div>
            </div>
         </div>
      </div>
   </div>
   <!--seven modal end -->
   <!--eight modal start -->
   <div class="modal fade" id="eight" tabindex="-1" role="dialog" aria-labelledby="exampleModalLongTitle" aria-hidden="true">
      <div class="modal-dialog" role="document">
         <div class="modal-content">
            <div class="red_pop__head_design">
               <h3 class="Betting__Popup-head-title">Win Go 1Min</h3>
               <div class="selected-color">
                  <p>Select &nbsp; &nbsp;&nbsp;&nbsp; 8</p>  
               </div>
            </div>
            <div class="model__body__content">
               <p>Balance</p>
               <div :class="['r1', { active_red: activeFactor === 1 }]" @click="multiply(1)">1</div>
               <div :class="['r2', { active_red: activeFactor === 10 }]" @click="multiply(10)">10</div>
               <div :class="['r3', { active_red: activeFactor === 100 }]" @click="multiply(100)">100</div>
               <div :class="['r4', { active_red: activeFactor === 1000 }]" @click="multiply(1000)">1000</div>
            </div>
            <div class="model__body__content" style="margin-top:0">
               <p>Quantity</p>
               <div class="r1 active-1_red" @click="decrement">-</div>
               <input type="number" value="1" v-model="value" class="quantity__input" @input="updateMultipliedValue">
               <div class="r1 active-10_red" @click="increment">+</div>
            </div>
            <div class="model__body__content" style="margin-top:0">
               <div v-if="value === 1" class="red_1x active_red" @click="updateInput(1)">X1</div>
               <div v-else class="red_1x" @click="updateInput(1)">X1</div>
               <div v-if="value === 5" class="red_5x active_red" @click="updateInput(5)">X5</div>
               <div v-else class="red_5x" @click="updateInput(5)">X5</div>
               <div v-if="value === 10" class="red_5x active_red" @click="updateInput(10)">X10</div>
               <div v-else class="red_10x" @click="updateInput(10)">X10</div>
               <div v-if="value === 20" class="red_10x active_red" @click="updateInput(20)">X20</div>
               <div v-else class="red_10x" @click="updateInput(20)">X20</div>
               <div v-if="value === 50" class="red_50x active_red" @click="updateInput(50)">X50</div>
               <div v-else class="red_50x" @click="updateInput(50)">X50</div>
               <div v-if="value === 100" class="red_100x active_red" @click="updateInput(100)">X100</div>
               <div v-else class="red_100x" @click="updateInput(100)">X100</div>
            </div>
            <div class="model__body__content" style="margin-top:0; margin-bottom: 10px">
               <img src="../assets/png/agree-a-95c84913.png" alt="" width="20px" height="20px">
               <p class="pl-1">I agree</p>
               <p class="pl-1" style="color:var(--norm_red-color)">《Pre-sale rules》</p>
            </div>
            <div class="redModel__footer">
               <div class="cancel" @click="reload()" data-dismiss="modal">Cancel</div>
               <div class="order__place" @click="confirmbet8()" data-dismiss="modal">Total Amount ₹{{ multipliedValue }}.00</div>
            </div>
         </div>
      </div>
   </div>
   <!--eight modal end -->
   <!--nine modal start -->
   <div class="modal fade" id="nine" tabindex="-1" role="dialog" aria-labelledby="exampleModalLongTitle" aria-hidden="true">
      <div class="modal-dialog" role="document">
         <div class="modal-content">
            <div class="green_pop__head_design">
               <h3 class="Betting__Popup-head-title">Win Go 1Min</h3>
               <div class="selected-color">
                  <p>Select &nbsp; &nbsp;&nbsp;&nbsp; 9</p>  
               </div>
            </div>
            <div class="model__body__content">
               <p>Balance</p>
               <div :class="['r1', { active_green: activeFactor === 1 }]" @click="multiply(1)">1</div>
               <div :class="['r2', { active_green: activeFactor === 10 }]" @click="multiply(10)">10</div>
               <div :class="['r3', { active_green: activeFactor === 100 }]" @click="multiply(100)">100</div>
               <div :class="['r4', { active_green: activeFactor === 1000 }]" @click="multiply(1000)">1000</div>
            </div>
            <div class="model__body__content" style="margin-top:0">
               <p>Quantity</p>
               <div class="r1 active-1" @click="decrement">-</div>
               <input type="number" value="1" v-model="value" class="quantity__input" @input="updateMultipliedValue">
               <div class="r1 active-10" @click="increment">+</div>
            </div>
            <div class="model__body__content" style="margin-top:0">
               <div v-if="value === 1" class="red_1x active_green" @click="updateInput(1)">X1</div>
               <div v-else class="red_1x" @click="updateInput(1)">X1</div>
               <div v-if="value === 5" class="red_5x active_green" @click="updateInput(5)">X5</div>
               <div v-else class="red_5x" @click="updateInput(5)">X5</div>
               <div v-if="value === 10" class="red_5x active_green" @click="updateInput(10)">X10</div>
               <div v-else class="red_10x" @click="updateInput(10)">X10</div>
               <div v-if="value === 20" class="red_10x active_green" @click="updateInput(20)">X20</div>
               <div v-else class="red_10x" @click="updateInput(20)">X20</div>
               <div v-if="value === 50" class="red_50x active_green" @click="updateInput(50)">X50</div>
               <div v-else class="red_50x" @click="updateInput(50)">X50</div>
               <div v-if="value === 100" class="red_100x active_green" @click="updateInput(100)">X100</div>
               <div v-else class="red_100x" @click="updateInput(100)">X100</div>
            </div>
            <div class="model__body__content" style="margin-top:0; margin-bottom: 10px">
               <img src="../assets/png/agree-a-95c84913.png" alt="" width="20px" height="20px">
               <p class="pl-1">I agree</p>
               <p class="pl-1" style="color:var(--norm_red-color)">《Pre-sale rules》</p>
            </div>
            <div class="greenModel__footer">
               <div class="cancel" @click="reload()" data-dismiss="modal">Cancel</div>
               <div class="order__place" @click="confirmbet9()" data-dismiss="modal">Total Amount ₹{{ multipliedValue }}.00</div>
            </div>
         </div>
      </div>
   </div>
   <!--nine modal end -->
   <!--big modal start -->
   <div class="modal fade" id="big" tabindex="-1" role="dialog" aria-labelledby="exampleModalLongTitle" aria-hidden="true">
      <div class="modal-dialog" role="document">
         <div class="modal-content">
            <div class="big_pop__head_design">
               <h3 class="Betting__Popup-head-title">Win Go 1Min</h3>
               <div class="selected-color">
                  <p>Select &nbsp; &nbsp;&nbsp; Big</p>  
               </div>
            </div>
            <div class="model__body__content">
               <p>Balance</p>
               <div :class="['r1', { active_big: activeFactor === 1 }]" @click="multiply(1)">1</div>
               <div :class="['r2', { active_big: activeFactor === 10 }]" @click="multiply(10)">10</div>
               <div :class="['r3', { active_big: activeFactor === 100 }]" @click="multiply(100)">100</div>
               <div :class="['r4', { active_big: activeFactor === 1000 }]" @click="multiply(1000)">1000</div>
            </div>
            <div class="model__body__content" style="margin-top:0">
               <p>Quantity</p>
               <div class="r1 active-1_big" @click="decrement">-</div>
               <input type="number" value="1" v-model="value" class="quantity__input" @input="updateMultipliedValue">
               <div class="r1 active-10_big" @click="increment">+</div>
            </div>
            <div class="model__body__content" style="margin-top:0">
               <div v-if="value === 1" class="red_1x active_big" @click="updateInput(1)">X1</div>
               <div v-else class="red_1x" @click="updateInput(1)">X1</div>
               <div v-if="value === 5" class="red_5x active_big" @click="updateInput(5)">X5</div>
               <div v-else class="red_5x" @click="updateInput(5)">X5</div>
               <div v-if="value === 10" class="red_5x active_big" @click="updateInput(10)">X10</div>
               <div v-else class="red_10x" @click="updateInput(10)">X10</div>
               <div v-if="value === 20" class="red_10x active_big" @click="updateInput(20)">X20</div>
               <div v-else class="red_10x" @click="updateInput(20)">X20</div>
               <div v-if="value === 50" class="red_50x active_big" @click="updateInput(50)">X50</div>
               <div v-else class="red_50x" @click="updateInput(50)">X50</div>
               <div v-if="value === 100" class="red_100x active_big" @click="updateInput(100)">X100</div>
               <div v-else class="red_100x" @click="updateInput(100)">X100</div>
            </div>
            <div class="model__body__content" style="margin-top:0; margin-bottom: 10px">
               <img src="../assets/png/agree-a-95c84913.png" alt="" width="20px" height="20px">
               <p class="pl-1">I agree</p>
               <p class="pl-1" style="color:var(--norm_red-color)">《Pre-sale rules》</p>
            </div>
            <div class="bigModel__footer">
               <div class="cancel" @click="reload()" data-dismiss="modal">Cancel</div>
               <div class="order__place" @click="confirmbetbig()" data-dismiss="modal">Total Amount ₹{{ multipliedValue }}.00</div>
            </div>
         </div>
      </div>
   </div>
   <!--big modal end -->
   <!--small modal start -->
   <div class="modal fade" id="small" tabindex="-1" role="dialog" aria-labelledby="exampleModalLongTitle" aria-hidden="true">
      <div class="modal-dialog" role="document">
         <div class="modal-content">
            <div class="small_pop__head_design">
               <h3 class="Betting__Popup-head-title">Win Go 1Min</h3>
               <div class="selected-color">
                  <p>Select &nbsp; &nbsp;&nbsp; Small</p>  
               </div>
            </div>
            <div class="model__body__content">
               <p>Balance</p>
               <div :class="['r1', { active_small: activeFactor === 1 }]" @click="multiply(1)">1</div>
               <div :class="['r2', { active_small: activeFactor === 10 }]" @click="multiply(10)">10</div>
               <div :class="['r3', { active_small: activeFactor === 100 }]" @click="multiply(100)">100</div>
               <div :class="['r4', { active_small: activeFactor === 1000 }]" @click="multiply(1000)">1000</div>
            </div>
            <div class="model__body__content" style="margin-top:0">
               <p>Quantity</p>
               <div class="r1 active-1_small" @click="decrement">-</div>
               <input type="number" value="1" v-model="value" class="quantity__input" @input="updateMultipliedValue">
               <div class="r1 active-10_small" @click="increment">+</div>
            </div>
            <div class="model__body__content" style="margin-top:0">
               <div v-if="value === 1" class="red_1x active_small" @click="updateInput(1)">X1</div>
               <div v-else class="red_1x" @click="updateInput(1)">X1</div>
               <div v-if="value === 5" class="red_5x active_small" @click="updateInput(5)">X5</div>
               <div v-else class="red_5x" @click="updateInput(5)">X5</div>
               <div v-if="value === 10" class="red_5x active_small" @click="updateInput(10)">X10</div>
               <div v-else class="red_10x" @click="updateInput(10)">X10</div>
               <div v-if="value === 20" class="red_10x active_small" @click="updateInput(20)">X20</div>
               <div v-else class="red_10x" @click="updateInput(20)">X20</div>
               <div v-if="value === 50" class="red_50x active_small" @click="updateInput(50)">X50</div>
               <div v-else class="red_50x" @click="updateInput(50)">X50</div>
               <div v-if="value === 100" class="red_100x active_small" @click="updateInput(100)">X100</div>
               <div v-else class="red_100x" @click="updateInput(100)">X100</div>
            </div>
            <div class="model__body__content" style="margin-top:0; margin-bottom: 10px">
               <img src="../assets/png/agree-a-95c84913.png" alt="" width="20px" height="20px">
               <p class="pl-1">I agree</p>
               <p class="pl-1" style="color:var(--norm_red-color)">《Pre-sale rules》</p>
            </div>
            <div class="smallModel__footer">
               <div class="cancel" @click="reload()" data-dismiss="modal">Cancel</div>
               <div class="order__place" @click="confirmbetsmall()" data-dismiss="modal">Total Amount ₹{{ multipliedValue }}.00</div>
            </div>
         </div>
      </div>
   </div>
   <!--small modal end -->
   <div id="snackbar" class="van-toast van-toast--middle van-toast--text" style="z-index: 2009;display:none ">
       <div class="van-toast__text">success</div>
   </div>
</template>
<script>
   require('../assets/css/game.css');
   import axios from 'axios';
   export default {
       name: "WingoView",
       props: ['rowsrec'],
       beforeCreate: function () {
          if (localStorage.getItem('username') == null)
              this.$router.push('Login');
         },
       data() {
         return{
            minutes: 0,
            seconds: '00',
            showSecondTimer: false,
            userdetails: {
               username: null,
               balance: null,
               period: null,
               condition: null,
               popup_status: null,
               result_period: null,
               ans: null,
               amount: null,
               result: null,
               number: null,
               color: null,
               color2: null,

           },
            value: 1,
            activeValue: 1,
            activeFactor: 1,
            multipliedValue: 1,
            currentTab: 'tab1', // Default tab
            timeLeft: 60, // 1 minute in seconds
            intervalId: null,
            items: [],
            betbox: {
                    title: null,
                    target: null,
                    select_ans: null,
                    boxcolor: null,
                    timeout: false,
                    multiply: 1,
                    factor: 10,
                    page: 1,
                    page1: 1,
                    total: 1000,
                    total1: 1000,
    
                },
               resultrec: '',
               info: '',
               betrec: '',
               openIndex: null
           }
         },
         computed: {
            limitedItems() {
               return this.items.slice(0, 5);
            },
            minutesString() {
               return '0' + this.minutes;
            },
           },
           mounted() {
               this.startCountDown();
               setInterval(this.startCountDown, 1000);
            },
           methods: {
            startCountDown() {
                  const countDownDate = Math.floor(Date.now() / 1000);
                  const distance = 120 - (countDownDate % 120);

                  this.minutes = Math.floor(distance / 60);
                  this.seconds = ('0' + Math.floor(distance % 60)).slice(-2);

                  // Show second timer only when 10 seconds or less are left
                  this.showSecondTimer = distance <= 10;

                  if (distance === 11) {
                     window.location.reload();
                  }
                  if(distance === 120 || distance === 119 || distance === 118 || distance === 117 || distance === 116 || distance === 115 || distance === 114 || distance === 113 || distance === 112 || distance === 111 || distance === 110)
                   {
                      this.fetchData();
                   }
            },
            toggle(index) {
               this.openIndex = this.openIndex === index ? null : index;  // Toggle open item
            },
            pop(message) {
               document.getElementById("snackbar").innerHTML = message;
               document.getElementById("snackbar").style.display = "";
               setTimeout(function () { document.getElementById("snackbar").style.display = "none"; }, 1000);
            },
            multiply(factor) {
               this.multipliedValue = this.value * factor;
               this.activeFactor = factor;
            },
            updateMultipliedValue() {
               if (this.activeFactor) {
                  this.multipliedValue = this.value * this.activeFactor;
               }
            },
            increment() {
               this.value++;
               this.multipliedValue = this.value * this.activeFactor;
            },
            decrement() {
               if (this.value > 1) {
                  this.value--;
                  this.multipliedValue = this.value * this.activeFactor;
               }
            },
            updateInput(value) {
               this.value = value;
               this.activeValue = value;
               this.multipliedValue = this.activeValue * this.activeFactor;
            },
            
            up() {
                this.betbox.page = this.betbox.page + 1;
                this.fetchData();
            },
            down() {
                if (this.betbox.page > 1) {
                    this.betbox.page = this.betbox.page - 1;
                    this.fetchData();
                } else {
                    this.pop("First Page Now!")
                }
    
            },
            up1() {
                if(this.betbox.total1/10>this.betbox.page1){
                this.betbox.page1 = this.betbox.page1 + 1;
                this.fetchData();  
                }
               
            },
            down1() {
                if (this.betbox.page1 > 1) {
                    this.betbox.page1 = this.betbox.page1 - 1;
                    this.fetchData();
                } else {
                    this.pop("First Page Now!")
                }
    
            },
            close_popup() {
                     var data = new FormData();
                     data.append("username", this.userdetails.username);
                     data.append("id", this.userdetails.id);
                     axios
                        .post(
                           "https://manager.royal60club.online/trova/src/api/bet.php?action=close_popup3",
                           data
                        )
                        .then(res => {
                           console.log(res.data);
                           // this.pop("Closed");
                           this.fetchData();   
                        })
                        .catch(err => {
                           console.log("Error", err);
                        });

            },
            reload() {
               this.multiply(1);
               this.updateMultipliedValue(1);
               this.value;
               this.increment();
               this.decrement();
               this.updateInput(1);
               this.activeFactor;
            },
            confirmbetgreen() {
               if (this.userdetails.balance >= this.multipliedValue) {
                     var data = new FormData();
                     data.append("username", this.userdetails.username);
                     data.append("period", this.userdetails.period);
                     data.append("amount", this.multipliedValue);
                     data.append("ans", "green");
                     data.append("ans", "green");
                     axios
                        .post(
                           "https://manager.royal60club.online/trova/src/api/bet.php?action=bet3",
                           data
                        )
                        .then(res => {
                           if (res.data.error==false) {
                              console.log("Success", res.data.message);
                              this.pop(res.data.message);
                              this.fetchData();  
                              this.multiply(1);
                              this.updateMultipliedValue(1);
                              this.value;
                              this.increment();
                              this.decrement();
                              this.updateInput(1);
                              this.activeFactor;
                           }
                           else{
                              console.log("Error", res.data);
                             this.pop(res.data.message);
                           }   
                        })
                        .catch(err => {
                           console.log("Error", err);
                        });
               } else {
                     this.pop("Your balance is insufficient");
                     this.multiply(1);
                              this.updateMultipliedValue(1);
                              this.value;
                              this.increment();
                              this.decrement();
                              this.updateInput(1);
                              this.activeFactor; 
               }

            },
            confirmbetviolet() {
               if (this.userdetails.balance >= this.multipliedValue) {
                     var data = new FormData();
                     data.append("username", this.userdetails.username);
                     data.append("period", this.userdetails.period);
                     data.append("amount", this.multipliedValue);
                     data.append("ans", "violet");
                     data.append("select_ans", "violet");
                     axios
                        .post(
                           "https://manager.royal60club.online/trova/src/api/bet.php?action=bet3",
                           data
                        )
                        .then(res => {
                           if (res.data.error==false) {
                              console.log("Success", res.data.message);
                              this.pop(res.data.message);
                              this.fetchData();  
                              this.multiply(1);
                              this.updateMultipliedValue(1);
                              this.value;
                              this.increment();
                              this.decrement();
                              this.updateInput(1);
                              this.activeFactor;
                           }
                           else{
                              console.log("Error", res.data);
                             this.pop(res.data.message);
                           }  
                        })
                        .catch(err => {
                           console.log("Error", err);
                        });
               } else {
                     this.pop("Your balance is insufficient");
                     this.multiply(1);
                              this.updateMultipliedValue(1);
                              this.value;
                              this.increment();
                              this.decrement();
                              this.updateInput(1);
                              this.activeFactor; 
               }

            },
            confirmbetred() {
               if (this.userdetails.balance >= this.multipliedValue) {
                     var data = new FormData();
                     data.append("username", this.userdetails.username);
                     data.append("period", this.userdetails.period);
                     data.append("amount", this.multipliedValue);
                     data.append("ans", "red");
                     data.append("select_ans", "red");
                     axios
                        .post(
                           "https://manager.royal60club.online/trova/src/api/bet.php?action=bet3",
                           data
                        )
                        .then(res => {
                           if (res.data.error==false) {
                              console.log("Success", res.data.message);
                              this.pop(res.data.message);
                              this.fetchData();  
                              this.multiply(1);
                              this.updateMultipliedValue(1);
                              this.value;
                              this.increment();
                              this.decrement();
                              this.updateInput(1);
                              this.activeFactor;
                           }
                           else{
                              console.log("Error", res.data);
                             this.pop(res.data.message);
                           }
                        })
                        .catch(err => {
                           console.log("Error", err);
                        });
               } else {
                     this.pop("Your balance is insufficient");
                     this.multiply(1);
                              this.updateMultipliedValue(1);
                              this.value;
                              this.increment();
                              this.decrement();
                              this.updateInput(1);
                              this.activeFactor; 
               }

            },
            confirmbet0() {
               if (this.userdetails.balance >= this.multipliedValue) {
                     var data = new FormData();
                     data.append("username", this.userdetails.username);
                     data.append("period", this.userdetails.period);
                     data.append("amount", this.multipliedValue);
                     data.append("ans", "0");
                     data.append("select_ans", "0");
                     axios
                        .post(
                           "https://manager.royal60club.online/trova/src/api/bet.php?action=bet3",
                           data
                        )
                        .then(res => {
                           if (res.data.error==false) {
                              console.log("Success", res.data.message);
                              this.pop(res.data.message);
                              this.fetchData();  
                              this.multiply(1);
                              this.updateMultipliedValue(1);
                              this.value;
                              this.increment();
                              this.decrement();
                              this.updateInput(1);
                              this.activeFactor;
                           }
                           else{
                              console.log("Error", res.data);
                             this.pop(res.data.message);
                           }
                        })
                        .catch(err => {
                           console.log("Error", err);
                        });
               } else {
                     this.pop("Your balance is insufficient");
                     this.multiply(1);
                              this.updateMultipliedValue(1);
                              this.value;
                              this.increment();
                              this.decrement();
                              this.updateInput(1);
                              this.activeFactor; 
               }

            },
            confirmbet1() {
               if (this.userdetails.balance >= this.multipliedValue) {
                     var data = new FormData();
                     data.append("username", this.userdetails.username);
                     data.append("period", this.userdetails.period);
                     data.append("amount", this.multipliedValue);
                     data.append("ans", "1");
                     data.append("select_ans", "1");
                     axios
                        .post(
                           "https://manager.royal60club.online/trova/src/api/bet.php?action=bet3",
                           data
                        )
                        .then(res => {
                           if (res.data.error==false) {
                              console.log("Success", res.data.message);
                              this.pop(res.data.message);
                              this.fetchData();  
                              this.multiply(1);
                              this.updateMultipliedValue(1);
                              this.value;
                              this.increment();
                              this.decrement();
                              this.updateInput(1);
                              this.activeFactor;
                           }
                           else{
                              console.log("Error", res.data);
                             this.pop(res.data.message);
                           }
                        })
                        .catch(err => {
                           console.log("Error", err);
                        });
               } else {
                     this.pop("Your balance is insufficient");
                     this.multiply(1);
                              this.updateMultipliedValue(1);
                              this.value;
                              this.increment();
                              this.decrement();
                              this.updateInput(1);
                              this.activeFactor; 
               }

            },
            confirmbet2() {
               if (this.userdetails.balance >= this.multipliedValue) {
                     var data = new FormData();
                     data.append("username", this.userdetails.username);
                     data.append("period", this.userdetails.period);
                     data.append("amount", this.multipliedValue);
                     data.append("ans", "2");
                     data.append("select_ans", "2");
                     axios
                        .post(
                           "https://manager.royal60club.online/trova/src/api/bet.php?action=bet3",
                           data
                        )
                        .then(res => {
                           if (res.data.error==false) {
                              console.log("Success", res.data.message);
                              this.pop(res.data.message);
                              this.fetchData();  
                              this.multiply(1);
                              this.updateMultipliedValue(1);
                              this.value;
                              this.increment();
                              this.decrement();
                              this.updateInput(1);
                              this.activeFactor;
                           }
                           else{
                              console.log("Error", res.data);
                             this.pop(res.data.message);
                           }
                        })
                        .catch(err => {
                           console.log("Error", err);
                        });
               } else {
                     this.pop("Your balance is insufficient");
                     this.multiply(1);
                              this.updateMultipliedValue(1);
                              this.value;
                              this.increment();
                              this.decrement();
                              this.updateInput(1);
                              this.activeFactor; 
               }

            },
            confirmbet3() {
               if (this.userdetails.balance >= this.multipliedValue) {
                     var data = new FormData();
                     data.append("username", this.userdetails.username);
                     data.append("period", this.userdetails.period);
                     data.append("amount", this.multipliedValue);
                     data.append("ans", "3");
                     data.append("select_ans", "3");
                     axios
                        .post(
                           "https://manager.royal60club.online/trova/src/api/bet.php?action=bet3",
                           data
                        )
                        .then(res => {
                           if (res.data.error==false) {
                              console.log("Success", res.data.message);
                              this.pop(res.data.message);
                              this.fetchData();  
                              this.multiply(1);
                              this.updateMultipliedValue(1);
                              this.value;
                              this.increment();
                              this.decrement();
                              this.updateInput(1);
                              this.activeFactor;
                           }
                           else{
                              console.log("Error", res.data);
                             this.pop(res.data.message);
                           }
                        })
                        .catch(err => {
                           console.log("Error", err);
                        });
               } else {
                     this.pop("Your balance is insufficient");
                     this.multiply(1);
                              this.updateMultipliedValue(1);
                              this.value;
                              this.increment();
                              this.decrement();
                              this.updateInput(1);
                              this.activeFactor; 
               }

            },
            confirmbet4() {
               if (this.userdetails.balance >= this.multipliedValue) {
                     var data = new FormData();
                     data.append("username", this.userdetails.username);
                     data.append("period", this.userdetails.period);
                     data.append("amount", this.multipliedValue);
                     data.append("ans", "4");
                     data.append("select_ans", "4");
                     axios
                        .post(
                           "https://manager.royal60club.online/trova/src/api/bet.php?action=bet3",
                           data
                        )
                        .then(res => {
                           if (res.data.error==false) {
                              console.log("Success", res.data.message);
                              this.pop(res.data.message);
                              this.fetchData();  
                              this.multiply(1);
                              this.updateMultipliedValue(1);
                              this.value;
                              this.increment();
                              this.decrement();
                              this.updateInput(1);
                              this.activeFactor;
                           }
                           else{
                              console.log("Error", res.data);
                             this.pop(res.data.message);
                           }
                        })
                        .catch(err => {
                           console.log("Error", err);
                        });
               } else {
                     this.pop("Your balance is insufficient");
                     this.multiply(1);
                              this.updateMultipliedValue(1);
                              this.value;
                              this.increment();
                              this.decrement();
                              this.updateInput(1);
                              this.activeFactor; 
               }

            },
            confirmbet5() {
               if (this.userdetails.balance >= this.multipliedValue) {
                     var data = new FormData();
                     data.append("username", this.userdetails.username);
                     data.append("period", this.userdetails.period);
                     data.append("amount", this.multipliedValue);
                     data.append("ans", "5");
                     data.append("select_ans", "5");
                     axios
                        .post(
                           "https://manager.royal60club.online/trova/src/api/bet.php?action=bet3",
                           data
                        )
                        .then(res => {
                           if (res.data.error==false) {
                              console.log("Success", res.data.message);
                              this.pop(res.data.message);
                              this.fetchData();  
                              this.multiply(1);
                              this.updateMultipliedValue(1);
                              this.value;
                              this.increment();
                              this.decrement();
                              this.updateInput(1);
                              this.activeFactor;
                           }
                           else{
                              console.log("Error", res.data);
                             this.pop(res.data.message);
                           }
                        })
                        .catch(err => {
                           console.log("Error", err);
                        });
               } else {
                     this.pop("Your balance is insufficient");
                     this.multiply(1);
                              this.updateMultipliedValue(1);
                              this.value;
                              this.increment();
                              this.decrement();
                              this.updateInput(1);
                              this.activeFactor; 
               }

            },
            confirmbet6() {
               if (this.userdetails.balance >= this.multipliedValue) {
                     var data = new FormData();
                     data.append("username", this.userdetails.username);
                     data.append("period", this.userdetails.period);
                     data.append("amount", this.multipliedValue);
                     data.append("ans", "6");
                     data.append("select_ans", "6");
                     axios
                        .post(
                           "https://manager.royal60club.online/trova/src/api/bet.php?action=bet3",
                           data
                        )
                        .then(res => {
                           if (res.data.error==false) {
                              console.log("Success", res.data.message);
                              this.pop(res.data.message);
                              this.fetchData();  
                              this.multiply(1);
                              this.updateMultipliedValue(1);
                              this.value;
                              this.increment();
                              this.decrement();
                              this.updateInput(1);
                              this.activeFactor;
                           }
                           else{
                              console.log("Error", res.data);
                             this.pop(res.data.message);
                           }
                        })
                        .catch(err => {
                           console.log("Error", err);
                        });
               } else {
                     this.pop("Your balance is insufficient");
                     this.multiply(1);
                              this.updateMultipliedValue(1);
                              this.value;
                              this.increment();
                              this.decrement();
                              this.updateInput(1);
                              this.activeFactor; 
               }

            },
            confirmbet7() {
               if (this.userdetails.balance >= this.multipliedValue) {
                     var data = new FormData();
                     data.append("username", this.userdetails.username);
                     data.append("period", this.userdetails.period);
                     data.append("amount", this.multipliedValue);
                     data.append("ans", "7");
                     data.append("select_ans", "7");
                     axios
                        .post(
                           "https://manager.royal60club.online/trova/src/api/bet.php?action=bet3",
                           data
                        )
                        .then(res => {
                           if (res.data.error==false) {
                              console.log("Success", res.data.message);
                              this.pop(res.data.message);
                              this.fetchData();  
                              this.multiply(1);
                              this.updateMultipliedValue(1);
                              this.value;
                              this.increment();
                              this.decrement();
                              this.updateInput(1);
                              this.activeFactor;
                           }
                           else{
                              console.log("Error", res.data);
                             this.pop(res.data.message);
                           }
                        })
                        .catch(err => {
                           console.log("Error", err);
                        });
               } else {
                     this.pop("Your balance is insufficient");
                     this.multiply(1);
                              this.updateMultipliedValue(1);
                              this.value;
                              this.increment();
                              this.decrement();
                              this.updateInput(1);
                              this.activeFactor; 
               }

            },
            confirmbet8() {
               if (this.userdetails.balance >= this.multipliedValue) {
                     var data = new FormData();
                     data.append("username", this.userdetails.username);
                     data.append("period", this.userdetails.period);
                     data.append("amount", this.multipliedValue);
                     data.append("ans", "8");
                     data.append("select_ans", "8");
                     axios
                        .post(
                           "https://manager.royal60club.online/trova/src/api/bet.php?action=bet3",
                           data
                        )
                        .then(res => {
                           if (res.data.error==false) {
                              console.log("Success", res.data.message);
                              this.pop(res.data.message);
                              this.fetchData();  
                              this.multiply(1);
                              this.updateMultipliedValue(1);
                              this.value;
                              this.increment();
                              this.decrement();
                              this.updateInput(1);
                              this.activeFactor;
                           }
                           else{
                              console.log("Error", res.data);
                             this.pop(res.data.message);
                           }  
                        })
                        .catch(err => {
                           console.log("Error", err);
                        });
               } else {
                     this.pop("Your balance is insufficient");
                     this.multiply(1);
                              this.updateMultipliedValue(1);
                              this.value;
                              this.increment();
                              this.decrement();
                              this.updateInput(1);
                              this.activeFactor; 
               }

            },
            confirmbet9() {
               if (this.userdetails.balance >= this.multipliedValue) {
                     var data = new FormData();
                     data.append("username", this.userdetails.username);
                     data.append("period", this.userdetails.period);
                     data.append("amount", this.multipliedValue);
                     data.append("ans", "9");
                     data.append("select_ans", "9");
                     axios
                        .post(
                           "https://manager.royal60club.online/trova/src/api/bet.php?action=bet3",
                           data
                        )
                        .then(res => {
                           if (res.data.error==false) {
                              console.log("Success", res.data.message);
                              this.pop(res.data.message);
                              this.fetchData();  
                              this.multiply(1);
                              this.updateMultipliedValue(1);
                              this.value;
                              this.increment();
                              this.decrement();
                              this.updateInput(1);
                              this.activeFactor;
                           }
                           else{
                              console.log("Error", res.data);
                             this.pop(res.data.message);
                           }
                        })
                        .catch(err => {
                           console.log("Error", err);
                        });
               } else {
                     this.pop("Your balance is insufficient");
                     this.multiply(1);
                              this.updateMultipliedValue(1);
                              this.value;
                              this.increment();
                              this.decrement();
                              this.updateInput(1);
                              this.activeFactor; 
               }

            },
            confirmbetbig() {
               if (this.userdetails.balance >= this.multipliedValue) {
                     var data = new FormData();
                     data.append("username", this.userdetails.username);
                     data.append("period", this.userdetails.period);
                     data.append("amount", this.multipliedValue);
                     data.append("ans", "big");
                     data.append("select_ans", "big");
                     axios
                        .post(
                           "https://manager.royal60club.online/trova/src/api/bet.php?action=bet3",
                           data
                        )
                        .then(res => {
                           if (res.data.error==false) {
                              console.log("Success", res.data.message);
                              this.pop(res.data.message);
                              this.fetchData();  
                              this.multiply(1);
                              this.updateMultipliedValue(1);
                              this.value;
                              this.increment();
                              this.decrement();
                              this.updateInput(1);
                              this.activeFactor;
                           }
                           else{
                              console.log("Error", res.data);
                             this.pop(res.data.message);
                           }
                        })
                        .catch(err => {
                           console.log("Error", err);
                        });
               } else {
                     this.pop("Your balance is insufficient");
                     this.multiply(1);
                              this.updateMultipliedValue(1);
                              this.value;
                              this.increment();
                              this.decrement();
                              this.updateInput(1);
                              this.activeFactor; 
               }

            },
            confirmbetsmall() {
               if (this.userdetails.balance >= this.multipliedValue) {
                     var data = new FormData();
                     data.append("username", this.userdetails.username);
                     data.append("period", this.userdetails.period);
                     data.append("amount", this.multipliedValue);
                     data.append("ans", "small");
                     data.append("select_ans", "small");
                     axios
                        .post(
                           "https://manager.royal60club.online/trova/src/api/bet.php?action=bet3",
                           data
                        )
                        .then(res => {
                           if (res.data.error==false) {
                              console.log("Success", res.data.message);
                              this.pop(res.data.message);
                              this.fetchData();  
                              this.multiply(1);
                              this.updateMultipliedValue(1);
                              this.value;
                              this.increment();
                              this.decrement();
                              this.updateInput(1);
                              this.activeFactor;
                           }
                           else{
                              console.log("Error", res.data);
                             this.pop(res.data.message);
                           }
                        })
                        .catch(err => {
                           console.log("Error", err);
                        });
               } else {
                     this.pop("Your balance is insufficient");
                     this.multiply(1);
                              this.updateMultipliedValue(1);
                              this.value;
                              this.increment();
                              this.decrement();
                              this.updateInput(1);
                              this.activeFactor; 
               }

            },
             fetchData() {
                axios.get('https://manager.royal60club.online/trova/src/api/bet.php?action=resultrec3&page=' + this.betbox.page)
                  .then(res => {
                      this.items = res.data;
                      console.log(this.items);
                  })
                  .catch(err => {
                      console.log(err);
                  });
                  axios.get('https://manager.royal60club.online/trova/src/api/bet.php?action=losewin3&username=' + this.userdetails.username).then((res) => {
                     this.losewin = res.data;
                     console.log(this.losewin);
                     this.userdetails.id = this.losewin[0].id;
                     this.userdetails.result_period = this.losewin[1].bet_period;
                     this.userdetails.condition = this.losewin[8].condition;
                     this.userdetails.ans = this.losewin[2].ans;
                     this.userdetails.amount = this.losewin[3].amount;
                     this.userdetails.result = this.losewin[4].result;
                     this.userdetails.number = this.losewin[5].number;
                     this.userdetails.color = this.losewin[6].color;
                     this.userdetails.color2 = this.losewin[7].color2;
                     this.userdetails.popup_status = this.losewin[9].popup_status;
               }).catch((err) => {
                     console.log(err);
               })
               
               this.userdetails.username = localStorage.getItem('username');
               axios.get('https://manager.royal60club.online/trova/src/api/bet.php?action=betrec3&user=' + this.userdetails.username + '&page1=' + this.betbox.page1).then((res) => {
                     this.betrec = res.data;
                     console.log(this.betrec);
               }).catch((err) => {
                     console.log(err);
               })
               axios.get('https://manager.royal60club.online/trova/src/api/bet.php?action=info&user=' + this.userdetails.username).then((res) => {
               this.info = res.data;
               this.userdetails.balance = this.info[0].balance;
               this.userdetails.period = this.info[6].period3;
               if (this.betbox.total1 > 0) {
                   document.getElementById("norecord").style.display = "none";
               }
               console.log(this.info);
               }).catch((err) => {
                     console.log(err);
               })
             },
             setTab(tab) {
                  this.currentTab = tab;
               },
           },
           created() {
               this.fetchData();
            },
   }
   
   
</script>
<style>
.van-toast {
position: fixed;
top: 50%;
left: 50%;
display: flex;
flex-direction: column;
align-items: center;
justify-content: center;
box-sizing: content-box;
width: 88px;
max-width: 70%;
min-height: 88px;
padding: 16px;
color: #fff;
font-size: 14px;
line-height: 20px;
white-space: pre-wrap;
text-align: center;
word-wrap: break-word;
background-color: rgba(50,50,51,.88);
border-radius: 8px;
transform: translate3d(-50%,-50%,0)
}

.van-toast--unclickable {
overflow: hidden
}

.van-toast--unclickable * {
pointer-events: none
}

.van-toast--html,.van-toast--text {
width: -webkit-fit-content;
width: -moz-fit-content;
width: fit-content;
min-width: 96px;
min-height: 0;
padding: 8px 12px
}

.van-toast--html .van-toast__text,.van-toast--text .van-toast__text {
margin-top: 0
}

.van-toast--top {
top: 50px
}

.van-toast--bottom {
top: auto;
bottom: 50px
}

.van-toast__icon {
font-size: 40px
}

.van-toast__loading {
padding: 4px;
color: #fff
}

.van-toast__text {
margin-top: 8px
}
</style>