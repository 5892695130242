<template>
   <div id="snackbar" class="van-toast van-toast--middle van-toast--text" style="z-index: 2009;display:none ">
       <div class="van-toast__text">success</div>
   </div>
   <div data-v-5bd44e74="" data-v-a78765c7="" class="userInfo__container" style="--f13b4d11-currentFontFamily: 'Roboto', 'Inter', sans-serif;">
      <div data-v-12a80a3e="" data-v-5bd44e74="" class="navbar main" style="display: none;">
         <div data-v-12a80a3e="" class="navbar-fixed">
            <div data-v-12a80a3e="" class="navbar__content">
               <div data-v-12a80a3e="" class="navbar__content-left">
                  <i data-v-12a80a3e="" class="van-badge__wrapper van-icon van-icon-arrow-left">
                     <!----><!----><!---->
                  </i>
               </div>
               <div data-v-12a80a3e="" class="navbar__content-center">
                  <!--v-if-->
                  <div data-v-12a80a3e="" class="navbar__content-title">Settings Center</div>
               </div>
               <div data-v-12a80a3e="" class="navbar__content-right"></div>
            </div>
         </div>
      </div>
      <div data-v-5bd44e74="" class="userInfo__container-content">
         <div data-v-5bd44e74="" class="userInfo__container-content-wrapper">
            <div data-v-5bd44e74="" class="userInfo__container-content__avatar">
               <img data-v-5bd44e74="" src="../assets/png/8-ea087ede.png" class="userAvatar">
            </div>
            <div data-v-5bd44e74="" class="userInfo__container-content__name">
               <div data-v-5bd44e74="" class="userInfo__container-content-nickname">
                  <h3 data-v-5bd44e74="" class="text-uppercase">{{ userdetails.name }} </h3>
                  <!-- <div data-v-5bd44e74="" class="n1"> -->
                     <img src="../assets/png/0-78e1ab02.png" alt="" class="n1" v-if="userdetails.vip === '0'">
                     <img src="../assets/png/1-953c5909.png" alt="" class="n1" v-if="userdetails.vip === '1'">
                     <img src="../assets/png/2-c9b115fb.png" alt="" class="n1" v-if="userdetails.vip === '2'">
                     <img src="../assets/png/3-fb0c8c43.png" alt="" class="n1" v-if="userdetails.vip === '3'">
                     <img src="../assets/png/4-3c5b4bba.png" alt="" class="n1" v-if="userdetails.vip === '4'">
                     <img src="../assets/png/5-5e49bae2.png" alt="" class="n1" v-if="userdetails.vip === '5'">
                     <img src="../assets/png/6-4ee4b170.png" alt="" class="n1" v-if="userdetails.vip === '6'">
                     <img src="../assets/png/7-907655eb.png" alt="" class="n1" v-if="userdetails.vip === '7'">
                     <img src="../assets/png/8-fc7f2447.png" alt="" class="n1" v-if="userdetails.vip === '8'">
                     <img src="../assets/png/9-5a25583e.png" alt="" class="n1" v-if="userdetails.vip === '9'">
                     <img src="../assets/png/10-b06f6562.png" alt="" class="n1" v-if="userdetails.vip === '10'">
                  <!-- </div> -->
                  <img data-v-5bd44e74="" class="editPencil" src="../assets/png/editPencil-c89ee923.png" style="display: none;">
               </div>
               <div data-v-5bd44e74="" class="userInfo__container-content-uid">
                  <span data-v-5bd44e74="">UID</span><span data-v-5bd44e74="">|</span><span data-v-5bd44e74="" id="link">{{ userdetails.usercode }}</span>
                  <svg data-v-5bd44e74="" class="svg-icon icon-copy" @click="copylink()">
                     <use xlink:href="#icon-copy"></use>
                  </svg>
               </div>
               <div data-v-5bd44e74="" class="userInfo__container-content-logintime"><span data-v-5bd44e74="">Last login:&nbsp;</span><span data-v-5bd44e74="">{{ userdetails.logindate }}</span></div>
               <div data-v-5bd44e74="" class="userInfo__container-content-logintime" style="display: none;"><span data-v-5bd44e74="">&nbsp;</span></div>
            </div>
         </div>
         <!-- <div v-show="!isSettingPage" class="userInfo__container-content-right">
            <img v-lazy="getIcons('main', 'myCoin')" alt="" />
            <span>我的金币</span>
            </div> -->
         <div data-v-5bd44e74="" class="userInfo__container-content-right" style="display: none;">
            <h5 data-v-5bd44e74="">Change avatar</h5>
         </div>
      </div>
      <div data-v-5bd44e74="" class="userInfo__container-setting-center" style="display: none;">
         <div data-v-5bd44e74="" class="userInfo__container-setting-center-header">
            <div data-v-5bd44e74="" class="userInfo__container-content__avatar"><img data-v-5bd44e74="" data-img="/assets/png/avatar-2f23f3bd.png" class="ar-lazyload" data-origin="/assets/png/8-ea087ede.png"></div>
            <div data-v-5bd44e74="" class="userInfo__container-setting-center-header-edit">
               <span data-v-5bd44e74="">Change avatar</span>
               <i data-v-5bd44e74="" class="van-badge__wrapper van-icon van-icon-arrow" style="color: rgb(136, 136, 136);">
                  <!----><!----><!---->
               </i>
            </div>
         </div>
         <div data-v-5bd44e74="" class="userInfo__container-setting-center-content ar-1px-b">
            <h5 data-v-5bd44e74="">Nickname</h5>
            <div data-v-5bd44e74="">
               <span data-v-5bd44e74="" class="text-uppercase">{{ userdetails.name }} </span>
               <i data-v-5bd44e74="" class="van-badge__wrapper van-icon van-icon-arrow" style="color: rgb(136, 136, 136);">
                  <!----><!----><!---->
               </i>
            </div>
         </div>
         <div data-v-5bd44e74="" class="userInfo__container-setting-center-content">
            <h5 data-v-5bd44e74="">UID</h5>
            <div data-v-5bd44e74="">
               <span data-v-5bd44e74="" id="link">{{ userdetails.userid }}</span>
               <svg data-v-5bd44e74="" class="svg-icon icon-copy">
                  <use xlink:href="#icon-copy"></use>
               </svg>
            </div>
         </div>
      </div>
      <div data-v-2c18a1cc="" data-v-5bd44e74="" class="info-dialog">
         <!----><!---->
      </div>
   </div>
   <div class="container">
      <div class="row">
         <div class="col-md-4"></div>
         <div class="col-md-4">
            <div class="row saving-part">
               <div data-v-7d799898="" class="totalSavings__container-header__title">
                  <span data-v-7d799898="">Total balance</span>
               </div>
               <p data-v-7d799898="" class="totalSavings__container-header__subtitle">
                  <span data-v-7d799898="">₹{{ userdetails.balance }}</span>
                  <svg data-v-7d799898="" class="svg-icon icon-refreshBalance">
                     <use xlink:href="#icon-refreshBalance"></use>
                  </svg>
               </p>
            </div>
            <div class="row saving-part2 text-center">
               <div class="col-3 items">
                  <svg class="svg-icon icon-wallets" width="40px">
                     <use @click="$router.push('wallet')" xlink:href="#icon-wallets"></use>
                  </svg>
                  <p style="margin-top: -50px;">Wallet</p>
               </div>
               <div class="col-3 items">
                  <svg data-v-7d799898="" class="svg-icon icon-rechargeIcon" width="40px">
                     <use xlink:href="#icon-rechargeIcon" @click="$router.push('recharge')"></use>
                  </svg>
                  <p style="margin-top: -50px;">Deposit</p>
               </div>
               <div class="col-3 items">
                  <svg data-v-7d799898="" class="svg-icon icon-widthdrawBlue" width="40px">
                     <use xlink:href="#icon-widthdrawBlue" @click="$router.push('withdrawal')"></use>
                  </svg>
                  <p style="margin-top: -50px;">Withdraw</p>
               </div>
               <div class="col-3 items">
                  <svg data-v-7d799898="" class="svg-icon icon-VipIcon" width="40px">
                     <use xlink:href="#icon-VipIcon"></use>
                  </svg>
                  <p style="margin-top: -50px;">Vip</p>
               </div>
            </div>
            <div class="row">
               <div class="col-5 col-6-card">
                  <svg data-v-acd6d46f="" class="svg-icon icon-betHistory" width="40px">
                     <use xlink:href="#icon-betHistory"></use>
                  </svg>
                  <p>Game History</p>
                  <p @click="$router.push('GameHistory')" class="p">My game history</p>
               </div>
               <div class="col-5 col-6-card">
                  <svg data-v-acd6d46f="" class="svg-icon icon-tradeHistory" width="40px">
                     <use xlink:href="#icon-tradeHistory"></use>
                  </svg>
                  <p @click="$router.push('MyTransaction')">Transaction</p>
                  <p class="p" @click="$router.push('MyTransaction')">My transaction history</p>
               </div>
               <div class="col-5 col-6-card">
                  <svg data-v-acd6d46f="" class="svg-icon icon-rechargeHistory" width="40px">
                     <use xlink:href="#icon-rechargeHistory"></use>
                  </svg>
                  <p @click="$router.push('RechargeHistory')">Deposit</p>
                  <p class="p" @click="$router.push('RechargeHistory')">My deposit history</p>
               </div>
               <div class="col-5 col-6-card">
                  <svg data-v-acd6d46f="" class="svg-icon icon-myWithdrawHistory" width="40px">
                     <use xlink:href="#icon-myWithdrawHistory"></use>
                  </svg>
                  <p @click="$router.push('WithdrawHistory')">Withdraw</p>
                  <p class="p" @click="$router.push('WithdrawHistory')">My withdraw history</p>
               </div>
            </div>
            <div data-v-a30d19b1="" data-v-a78765c7="" class="settingPanel__container">
               <div data-v-a30d19b1="" class="settingPanel__container-items">
                  <div data-v-a30d19b1="" @click="$router.push('Notification')" class="settingPanel__container-items__item ar-1px-b">
                     <div data-v-a30d19b1="" class="settingPanel__container-items__title">
                        <svg data-v-a30d19b1="" class="svg-icon icon-notification">
                           <use xlink:href="#icon-notification"></use>
                        </svg>
                        <span data-v-a30d19b1="">Notification</span>
                     </div>
                     <div data-v-a30d19b1="" class="settingPanel__container-items-right">
                        <!-- <h5 data-v-a30d19b1="">21</h5> -->
                        <span data-v-a30d19b1="" style="display: none;">English</span>
                        <i data-v-a30d19b1="" class="fa fa-angle-right" style="color: rgb(102, 102, 102); font-size: 20px; margin-top:-10px; padding-right: 10px">
                           <!----><!----><!---->
                        </i>
                     </div>
                  </div>
                  <div data-v-a30d19b1="" class="settingPanel__container-items__item ar-1px-b" @click="$router.push('GiftCode')">
                     <div data-v-a30d19b1="" class="settingPanel__container-items__title">
                        <svg data-v-a30d19b1="" class="svg-icon icon-gifts">
                           <use xlink:href="#icon-gifts"></use>
                        </svg>
                        <!-- <img :src="getIcons('main', `${item.icon}`)" /> --><span data-v-a30d19b1="">Gifts</span>
                     </div>
                     <div data-v-a30d19b1="" class="settingPanel__container-items-right">
                        <h5 data-v-a30d19b1="" style="display: none;">21</h5>
                        <span data-v-a30d19b1="" style="display: none;">English</span>
                        <i data-v-a30d19b1="" class="fa fa-angle-right" style="color: rgb(102, 102, 102); font-size: 20px; margin-top:-10px; padding-right: 10px">
                           <!----><!----><!---->
                        </i>
                     </div>
                  </div>
                  <div data-v-a30d19b1="" class="settingPanel__container-items__item ar-1px-b" @click="$router.push('ChangePassword')">
                     <div data-v-a30d19b1="" class="settingPanel__container-items__title">
                        <svg data-v-a30d19b1="" class="svg-icon icon-tournament">
                           <use xlink:href="#icon-clock_b"></use>
                        </svg>
                        <!-- <img :src="getIcons('main', `${item.icon}`)" /> --><span data-v-a30d19b1="">Change Password</span>
                     </div>
                     <div data-v-a30d19b1="" class="settingPanel__container-items-right">
                        <h5 data-v-a30d19b1="" style="display: none;">21</h5>
                        <span data-v-a30d19b1="" style="display: none;">English</span>
                        <i data-v-a30d19b1="" class="fa fa-angle-right" style="color: rgb(102, 102, 102); font-size: 20px; margin-top:-10px; padding-right: 10px">
                           <!----><!----><!---->
                        </i>
                     </div>
                  </div>
                  <div data-v-a30d19b1="" class="settingPanel__container-items__item ar-1px-b" style="display: none;">
                     <div data-v-a30d19b1="" class="settingPanel__container-items__title">
                        <svg data-v-a30d19b1="" class="svg-icon icon-productCode">
                           <use xlink:href="#icon-productCode"></use>
                        </svg>
                        <!-- <img :src="getIcons('main', `${item.icon}`)" /> --><span data-v-a30d19b1="">Product code</span>
                     </div>
                     <div data-v-a30d19b1="" class="settingPanel__container-items-right">
                        <h5 data-v-a30d19b1="" style="display: none;">21</h5>
                        <span data-v-a30d19b1="" style="display: none;">English</span>
                        <i data-v-a30d19b1="" class="fa fa-angle-right" style="color: rgb(102, 102, 102); font-size: 20px; margin-top:-10px; padding-right: 10px">
                           <!----><!----><!---->
                        </i>
                     </div>
                  </div>
                  <div data-v-a30d19b1="" class="settingPanel__container-items__item ar-1px-b" style="display: none;">
                     <div data-v-a30d19b1="" class="settingPanel__container-items__title">
                        <svg data-v-a30d19b1="" class="svg-icon icon-myDraw">
                           <use xlink:href="#icon-myDraw"></use>
                        </svg>
                        <!-- <img :src="getIcons('main', `${item.icon}`)" /> --><span data-v-a30d19b1="">my draw</span>
                     </div>
                     <div data-v-a30d19b1="" class="settingPanel__container-items-right">
                        <h5 data-v-a30d19b1="" style="display: none;">21</h5>
                        <span data-v-a30d19b1="" style="display: none;">English</span>
                        <i data-v-a30d19b1="" class="fa fa-angle-right" style="color: rgb(102, 102, 102); font-size: 20px; margin-top:-10px; padding-right: 10px">
                           <!----><!----><!---->
                        </i>
                     </div>
                  </div>
                  <div data-v-a30d19b1="" class="settingPanel__container-items__item ar-1px-b" @click="$router.push('GameStatistics')">
                     <div data-v-a30d19b1="" class="settingPanel__container-items__title">
                        <svg data-v-a30d19b1="" class="svg-icon icon-statsIcon">
                           <use xlink:href="#icon-statsIcon"></use>
                        </svg>
                        <!-- <img :src="getIcons('main', `${item.icon}`)" /> --><span data-v-a30d19b1="">Game statistics</span>
                     </div>
                     <div data-v-a30d19b1="" class="settingPanel__container-items-right">
                        <h5 data-v-a30d19b1="" style="display: none;">21</h5>
                        <span data-v-a30d19b1="" style="display: none;">English</span>
                        <i data-v-a30d19b1="" class="fa fa-angle-right" style="color: rgb(102, 102, 102); font-size: 20px; margin-top:-10px; padding-right: 10px">
                           <!----><!----><!---->
                        </i>
                     </div>
                  </div>
                  <div data-v-a30d19b1="" class="settingPanel__container-items__item ar-1px-b">
                     <div data-v-a30d19b1="" class="settingPanel__container-items__title">
                        <svg data-v-a30d19b1="" class="svg-icon icon-language">
                           <use xlink:href="#icon-language"></use>
                        </svg>
                        <!-- <img :src="getIcons('main', `${item.icon}`)" /> --><span data-v-a30d19b1="">Language</span>
                     </div>
                     <div data-v-a30d19b1="" class="settingPanel__container-items-right">
                        <h5 data-v-a30d19b1="" style="display: none;">21</h5>
                        <span data-v-a30d19b1="" style="font-size:13px; margin-top: -10px;">English</span>
                        <i data-v-a30d19b1="" class="fa fa-angle-right" style="color: rgb(102, 102, 102); font-size: 20px; margin-top:-10px; padding-right: 10px">
                           <!----><!----><!---->
                        </i>
                     </div>
                  </div>
               </div>
               <div data-v-159bf81f="" data-v-a78765c7="" class="serviceCenter-wrap">
                  <div data-v-159bf81f="" class="serviceCenter__container">
                     <h1 data-v-159bf81f="">Service center</h1>
                     <div data-v-159bf81f="" class="serviceCenter__container-items">
                        <div data-v-159bf81f="" class="serviceCenter__container-items__item">
                           <svg data-v-159bf81f="" class="svg-icon icon-settingCenter">
                              <use xlink:href="#icon-settingCenter"></use>
                           </svg>
                           <!-- <img v-lazy="getIcons('main', `${item.icon}`)" /> --><span data-v-159bf81f="">Settings</span>
                        </div>
                        <div data-v-159bf81f="" class="serviceCenter__container-items__item">
                           <svg data-v-159bf81f="" class="svg-icon icon-feedback">
                              <use xlink:href="#icon-feedback"></use>
                           </svg>
                           <!-- <img v-lazy="getIcons('main', `${item.icon}`)" /> --><span data-v-159bf81f="">Feedback</span>
                        </div>
                        <div data-v-159bf81f="" class="serviceCenter__container-items__item">
                           <svg data-v-159bf81f="" class="svg-icon icon-notificationCenter">
                              <use xlink:href="#icon-notificationCenter"></use>
                           </svg>
                           <!-- <img v-lazy="getIcons('main', `${item.icon}`)" /> --><span data-v-159bf81f="">Notification</span>
                        </div>
                        <div data-v-159bf81f="" class="serviceCenter__container-items__item" @click="$router.push('CustomerService')">
                           <svg data-v-159bf81f="" class="svg-icon icon-server">
                              <use xlink:href="#icon-server"></use>
                           </svg>
                           <!-- <img v-lazy="getIcons('main', `${item.icon}`)" /> --><span data-v-159bf81f="">24/7 Customer service</span>
                        </div>
                        <div data-v-159bf81f="" class="serviceCenter__container-items__item">
                           <svg data-v-159bf81f="" class="svg-icon icon-guide">
                              <use xlink:href="#icon-guide"></use>
                           </svg>
                           <!-- <img v-lazy="getIcons('main', `${item.icon}`)" /> --><span data-v-159bf81f="">Beginner's Guide</span>
                        </div>
                        <div data-v-159bf81f="" class="serviceCenter__container-items__item">
                           <svg data-v-159bf81f="" class="svg-icon icon-about">
                              <use xlink:href="#icon-about"></use>
                           </svg>
                           <!-- <img v-lazy="getIcons('main', `${item.icon}`)" /> --><span data-v-159bf81f="">About us</span>
                        </div>
                     </div>
                  </div>
                  <div data-v-159bf81f="" class="serviceCenter-wrap-header">
                     <button data-v-159bf81f="" @click="logout">
                        <svg data-v-159bf81f="" class="svg-icon icon-logout">
                           <use xlink:href="#icon-logout"></use>
                        </svg>
                        <!-- <img v-lazy="getIcons('home', 'logout')" /> --> Log out
                     </button>
                  </div>
                  <!--退出登录弹窗-->
               </div>
               
            </div>
            
         </div>
         <div class="col-md-4"></div>
      </div>
   </div>
   <br>
   <br>
   <br>
   <!-- footer-nav -->
   <div class="footer-nav" style="--f13b4d11-currentFontFamily: 'Roboto', 'Inter', sans-serif;">
      <div class="footer-nav-item" @click="$router.push('home')">
         <svg data-v-6ab3f23e="" class="svg-icon icon-home">
            <use xlink:href="#icon-home"></use>
         </svg>
         <!--v-if--><span data-v-6ab3f23e="">Home</span>
      </div>
      <div data-v-6ab3f23e="" class="footer-nav-item" @click="$router.push('activity')">
         <svg data-v-6ab3f23e="" class="svg-icon icon-activity">
            <use xlink:href="#icon-activity"></use>
         </svg>
         <!--v-if--><span data-v-6ab3f23e="">Activity</span>
      </div>
      <div data-v-6ab3f23e="" class="footer-nav-item" @click="$router.push('promotion')">
         <svg data-v-6ab3f23e="" class="svg-icon icon-promotion">
            <use xlink:href="#icon-promotion"></use>
         </svg>
         <div data-v-6ab3f23e="" class="promotionBg"></div>
         <span data-v-6ab3f23e="">Promotion</span>
      </div>
      <div data-v-6ab3f23e="" class="footer-nav-item" @click="$router.push('wallet')">
         <svg data-v-6ab3f23e="" class="svg-icon icon-wallet">
            <use xlink:href="#icon-wallet"></use>
         </svg>
         <!--v-if--><span data-v-6ab3f23e="">Wallet</span>
      </div>
      <div data-v-6ab3f23e="" class="footer-nav-item active" @click="$router.push('Mine')">
         <svg data-v-6ab3f23e="" class="svg-icon icon-main">
            <use xlink:href="#icon-main"></use>
         </svg>
         <!--v-if--><span data-v-6ab3f23e="">Account</span>
      </div>
   </div>
</template>
<script>
import axios from 'axios';
   export default {
       name: "MineView",
       data() {
       return {
           userdetails: {
               username: null,
               balance: null,
               usercode: null,
               commision: "0",
               intrest: "0",
               notice: "Welcome",

           },
           user: [],
           dailysign:[]
            }
      },
      beforeCreate: function () {
        if (localStorage.getItem('username') == null)
            this.$router.push('Login');
      },
      created: function () {
       this.getUserdetails();
      },
      methods: {
         pop(message) {
         document.getElementById("snackbar").innerHTML = message;
         document.getElementById("snackbar").style.display = "";
         setTimeout(function () { document.getElementById("snackbar").style.display = "none"; }, 1000);
       },
       getUserdetails() {
           this.userdetails.username = localStorage.getItem('username');
           axios.get('https://manager.royal60club.online/trova/src/api/me_api_tcgames.php?action=getuserinfo&user=' + this.userdetails.username).then((res) => {
               this.Users = res.data.user_Data;
               console.log(this.Users);
               this.userdetails.usercode = this.Users[0].usercode;
               this.userdetails.balance = this.Users[0].balance;
               this.userdetails.userid = this.Users[0].user_id;
               this.userdetails.name = this.Users[0].name;
               this.userdetails.vip = this.Users[0].vip;
               this.userdetails.logindate = this.Users[0].last_login_date;
               this.userdetails.notice = this.Users[1].notice;
               this.userdetails.commision = this.Users[2].bonus;
               this.userdetails.days=this.Users[3].days;
               this.userdetails.status=this.Users[4].status;
               
           }).catch((err) => {
               console.log(err);
           })

           axios.get('https://manager.royal60club.online/trova/src/api/bet.php?action=dailysign&username=' + this.userdetails.username).then((res) => {
               this.dailysign = res.data[0];
               console.log(this.dailysign);
               
           }).catch((err) => {
               console.log(err);
           })
       },
       copylink() {
            this.pop("Copied");
            var inputc = document.body.appendChild(document.createElement("input"));
            inputc.value = document.getElementById("link").innerHTML;
            // inputc.focus();
            inputc.select();
            document.execCommand('copy');
            inputc.parentNode.removeChild(inputc);
        },
       signday(){
           if(this.dailysign.status=='Not signed in'){
               document.getElementById("signbox").style.display = "none";
               this.pop("Success");
               axios.get('https://manager.royal60club.online/trova/src/api/bet.php?action=todaysign&username=' + this.userdetails.username)
           }else{
               this.pop("Had signed in");
           }
           

       },
         logout() {
         // Remove only the username from local storage
         localStorage.removeItem('username');
         
         // Redirect to login or home page if needed
         this.$router.push('/login'); // Example redirection, depends on your router setup
         }
      }
   }
</script>
<style>
.van-toast {
position: fixed;
top: 50%;
left: 50%;
display: flex;
flex-direction: column;
align-items: center;
justify-content: center;
box-sizing: content-box;
width: 88px;
max-width: 70%;
min-height: 88px;
padding: 16px;
color: #fff;
font-size: 14px;
line-height: 20px;
white-space: pre-wrap;
text-align: center;
word-wrap: break-word;
background-color: rgba(50,50,51,.88);
border-radius: 8px;
transform: translate3d(-50%,-50%,0)
}

.van-toast--unclickable {
overflow: hidden
}

.van-toast--unclickable * {
pointer-events: none
}

.van-toast--html,.van-toast--text {
width: -webkit-fit-content;
width: -moz-fit-content;
width: fit-content;
min-width: 96px;
min-height: 0;
padding: 8px 12px
}

.van-toast--html .van-toast__text,.van-toast--text .van-toast__text {
margin-top: 0
}

.van-toast--top {
top: 50px
}

.van-toast--bottom {
top: auto;
bottom: 50px
}

.van-toast__icon {
font-size: 40px
}

.van-toast__loading {
padding: 4px;
color: #fff
}

.van-toast__text {
margin-top: 8px
}
</style>