<template>
    <div data-v-36cc3380="" class="Recharge__box" style="--f13b4d11-currentFontFamily: 'Roboto', 'Inter', sans-serif;">
       <div data-v-36cc3380="" class="Recharge__container">
          <div data-v-12a80a3e="" data-v-36cc3380="" class="navbar white">
             <div data-v-12a80a3e="" class="navbar-fixed">
                <div data-v-12a80a3e="" class="navbar__content">
                   <div data-v-12a80a3e="" class="navbar__content-left">
                      <i data-v-12a80a3e="" @click="goBack" class="fa fa-angle-left van-icon van-icon-arrow-left" style="font-size:25px;">
                         <!----><!----><!---->
                      </i>
                   </div>
                   <div data-v-12a80a3e="" class="navbar__content-center">
                      <!--v-if-->
                      <div data-v-12a80a3e="" class="navbar__content-title nav-recharge-history">Add a bank Account</div>
                   </div>
                </div>
             </div>
          </div>
          <form>
          <div data-v-1726638e="" class="addBankCard__container-content">
             <div data-v-1726638e="" class="addBankCard__container-content-top"><img data-v-1726638e="" src="../assets/png/hint-d2d46a38.png"><span data-v-1726638e="">To ensure the safety of your funds, please bind your bank account</span></div>
             <div data-v-1726638e="" class="addBankCard__container-content-item">
                <div data-v-1726638e="" class="label">
                   <svg data-v-1726638e="" class="svg-icon icon-bank">
                      <use xlink:href="#icon-bank"></use>
                   </svg>
                   Choose a bank
                </div>
                <select data-v-1726638e="" class="selectB" v-model="userdetails.bankname">
                   <option value="" selected="">Please select a bank </option>
                   <option value="Union Bank of India">Union Bank of India</option>
                   <option value="Bank Of India">Bank Of India</option>
                   <option value="Central Bank Of India">Central Bank Of India</option>
                   <option value="Yes Bank">Yes Bank</option>
                   <option value="HDFC Bank">HDFC Bank</option>
                   <option value="Karnataka Bank">Karnataka Bank</option>
                   <option value="IDBI Bank">IDBI Bank</option>
                   <option value="Bank Of Baroda">Bank Of Baroda</option>
                   <option value="State Bank Of India">State Bank Of India</option>
                   <option value="ICICI Bank">ICICI Bank</option>
                   <option value="Punjab National Bank">Punjab National Bank</option>
                   <option value="Canara Bank">Canara Bank</option>
                   <option value="Indian Overseas Bank">Indian Overseas Bank</option>
                   <option value="Standard Chartered Bank">Standard Chartered Bank</option>
                   <option value="Kotak Mahindra Bank">Kotak Mahindra Bank</option>
                   <option value="Indian Bank">Indian Bank</option>
                   <option value="Axis Bank">Axis Bank</option>
                   <option value="Syndicate Bank">Syndicate Bank</option>
                   <option value="Citibank India">Citibank India</option>
                   <option value="IDFC Bank">IDFC Bank</option>
                   <option value="Bandhan Bank">Bandhan Bank</option>
                   <option value="Indusind Bank">Indusind Bank</option>
                   <option value="Equitas Bank">Equitas Bank</option>
                   <option value="Indian Post Payment Bank">Indian Post Payment Bank</option>
                   <option value="Corporation Bank">Corporation Bank</option>
                   <option value="City Union Bank">City Union Bank</option>
                   <option value="Karur Vysya Bank">Karur Vysya Bank</option>
                   <option value="Tamilnad Mercantile Bank">Tamilnad Mercantile Bank</option>
                   <option value="Allahabad Bank">Allahabad Bank</option>
                   <option value="Meghalaya Rural Bank">Meghalaya Rural Bank</option>
                   <option value="AU Small Finance Bank">AU Small Finance Bank</option>
                   <option value="Lakshmi Vilas Bank">Lakshmi Vilas Bank</option>
                   <option value="South Indian Bank">South Indian Bank</option>
                   <option value="Airtel Payment Bank">Airtel Payment Bank</option>
                   <option value="State Bank Of Hyderabad">State Bank Of Hyderabad</option>
                   <option value="Kerala Gramin Bank">Kerala Gramin Bank</option>
                   <option value="RBL Bank">RBL Bank</option>
                   <option value="Dhanlaxmi Bank">Dhanlaxmi Bank</option>
                   <option value="TJSB Bank">TJSB Bank</option>
                   <option value="Purvanchal Bank">Purvanchal Bank</option>
                   <option value="Sarva Haryana Bank">Sarva Haryana Bank</option>
                   <option value="Fino Payments Bank">Fino Payments Bank</option>
                   <option value="Telangana Grameena Bank">Telangana Grameena Bank</option>
                   <option value="Andhra Pragathi Grameena Bank">Andhra Pragathi Grameena Bank</option>
                   <option value="Rajasthan Marudhra Gramin Bank">Rajasthan Marudhra Gramin Bank</option>
                   <option value="Capital Small Finance Bank">Capital Small Finance Bank</option>
                   <option value="Mizoram Rural Bank">Mizoram Rural Bank</option>
                   <option value="Andhra Pradesh Grameena Vikas Bank">Andhra Pradesh Grameena Vikas Bank</option>
                   <option value="Karnataka Vikas Grameena Bank">Karnataka Vikas Grameena Bank</option>
                   <option value="Madhya Bihar Gramin Bank">Madhya Bihar Gramin Bank</option>
                   <option value="NSDL Payments Bank">NSDL Payments Bank</option>
                   <option value="ESAF Small Finance Bank">ESAF Small Finance Bank</option>
                   <option value="Himachal Preadesh State Cooperative Bank">Himachal Preadesh State Cooperative Bank</option>
                   <option value="Maharashtra State Cooperative Bank">Maharashtra State Cooperative Bank</option>
                   <option value="Oriental Bank Of Commerce">Oriental Bank Of Commerce</option>
                   <option value="Nanital Bank">Nanital Bank</option>
                   <option value="Jharkhand Rajya Gramin Bank">Jharkhand Rajya Gramin Bank</option>
                   <option value="Jio Payment Bank">Jio Payment Bank</option>
                   <option value="Maharashtra Gramin Bank">Maharashtra Gramin Bank</option>
                   <option value="Uttrakhand Gramin Bank">Uttrakhand Gramin Bank</option>
                   <option value="North East Small Finance Bank">North East Small Finance Bank</option>
                   <option value="Catholic Syrian Bank">Catholic Syrian Bank</option>
                   <option value="Fincare Small Finance Bank">Fincare Small Finance Bank</option>
                   <option value="Sarva Up Gramin Bank">Sarva Up Gramin Bank</option>
                   <option value="Vijaya Bank">Vijaya Bank</option>
                   <option value="United Bank Of India">United Bank Of India</option>
                   <option value="State Bank Of Bikaner & Jaipur">State Bank Of Bikaner & Jaipur</option>
                   <option value="Punjab Gramin Bank">Punjab Gramin Bank</option>
                   <option value="Chhattisgarh Rajya Gramin Bank">Chhattisgarh Rajya Gramin Bank</option>
                   <option value="Assam Gramin Vikash Bank">Assam Gramin Vikash Bank</option>
                </select>
             </div>
             <!--验证收款人姓名-->
             <div data-v-1726638e="" class="addBankCard__container-content-item">
                <div data-v-1726638e="" class="label">
                   <svg data-v-1726638e="" class="svg-icon icon-name">
                      <use xlink:href="#icon-name"></use>
                   </svg>
                   Full recipient's name
                </div>
                <input data-v-1726638e="" v-model="userdetails.name" placeholder="Please enter the recipient's name" maxlength="50" ><!--v-if--><!--v-if-->
             </div>
             <div data-v-1726638e="" class="addBankCard__container-content-item">
                <div data-v-1726638e="" class="label">
                   <svg data-v-1726638e="" class="svg-icon icon-bankCard">
                      <use xlink:href="#icon-bankCard"></use>
                   </svg>
                   Bank account number
                </div>
                <input data-v-1726638e="" v-model="userdetails.account_number" placeholder="Please enter your bank account number" maxlength="25">
             </div>
             <div data-v-1726638e="" class="addBankCard__container-content-item">
                <div data-v-1726638e="" class="label phone_icon">
                   <svg data-v-1726638e="" class="svg-icon icon-phone">
                      <use xlink:href="#icon-phone"></use>
                   </svg>
                   Phone number
                </div>
                <input data-v-1726638e="" placeholder="Please enter your phone number" maxlength="12">
             </div>
             <!--v-if-->
             <div data-v-1726638e="" class="addBankCard__container-content-item">
                <div data-v-1726638e="" class="label">
                   <svg data-v-1726638e="" class="svg-icon icon-ifscCode">
                      <use xlink:href="#icon-ifscCode"></use>
                   </svg>
                   IFSC code
                </div>
                <input data-v-1726638e="" v-model="userdetails.ifsc" placeholder="Please enterIFSC code" maxlength="11">
             </div>
             <!--v-if--><!--v-if-->
             <div data-v-1726638e="" class="addBankCard__container-content-btn"><button data-v-1726638e="" @click.prevent="addBank()" class="" style="background: var(--main-color);">Save</button></div>
          </div>
        </form>
            <div id="snackbar" class="van-toast van-toast--middle van-toast--text" style="z-index: 2009;display:none ">
                <div class="van-toast__text">success</div>
            </div>
       </div>
    </div>
 </template>
 <script>
    import axios from 'axios';
     export default {
         name: 'AddBankAccountView',
         data(){
             return{
                userdetails: {
                   username: null,
                   bankname: null,
                   name: null,
                   account_number: null,
                   ifsc: null,
                }
             }
         },
         beforeCreate: function () {
           if (localStorage.getItem('username') == null)
               this.$router.push('Login');
          },
         methods:{
          goBack() {
             window.history.back();
          },
          pop(message) {
             document.getElementById("snackbar").innerHTML = message;
             document.getElementById("snackbar").style.display = "";
             setTimeout(function () { document.getElementById("snackbar").style.display = "none"; }, 3000);
         },
          addBank() {
            this.userdetails.username=localStorage.getItem('username');
                if(this.userdetails.bankname === null){
                    this.pop("Please Choose Bank Name");
                }
                else if(this.userdetails.name === null){
                    this.pop("Please Enter Full Recipent's Name");
                }
                else if(this.userdetails.account_number === null){
                    this.pop("Please Enter Bank Account Number");
                }
                else if(this.userdetails.ifsc === null){
                    this.pop("Please Enter Bank IFSC Code");
                }
               else{
                     var data = new FormData();
                     data.append("username", this.userdetails.username);
                     data.append("bankname", this.userdetails.bankname);
                     data.append("name", this.userdetails.name);
                     data.append("account_number", this.userdetails.account_number);
                     data.append("ifsc", this.userdetails.ifsc);
                     axios
                        .post(
                           "https://manager.royal60club.online/trova/src/api/user_api_tcgames.php?add_bank",
                           data
                        )
                        .then(res => {
                            if (res.data.error==true) {
                           //   this.loading();
                             console.log("Success", res.data.message);
                             this.pop(res.data.message);
                             setTimeout(() => { this.$router.push('Withdrawal'); }, 1000);
                         } else {
                             
                             console.log("Error", res.data);
                             this.pop(res.data.message);
                         }  
                        })
                        .catch(err => {
                           console.log("Error", err);
                        });
               }
            },
         }
     }
 </script>
<style>
    option{
        background: var(--main-color);
    }
        .van-toast {
position: fixed;
top: 50%;
left: 50%;
display: flex;
flex-direction: column;
align-items: center;
justify-content: center;
box-sizing: content-box;
width: 88px;
max-width: 70%;
min-height: 88px;
padding: 16px;
color: #fff;
font-size: 14px;
line-height: 20px;
white-space: pre-wrap;
text-align: center;
word-wrap: break-word;
background-color: rgba(50,50,51,.88);
border-radius: 8px;
transform: translate3d(-50%,-50%,0)
}

.van-toast--unclickable {
overflow: hidden
}

.van-toast--unclickable * {
pointer-events: none
}

.van-toast--html,.van-toast--text {
width: -webkit-fit-content;
width: -moz-fit-content;
width: fit-content;
min-width: 96px;
min-height: 0;
padding: 8px 12px
}

.van-toast--html .van-toast__text,.van-toast--text .van-toast__text {
margin-top: 0
}

.van-toast--top {
top: 50px
}

.van-toast--bottom {
top: auto;
bottom: 50px
}

.van-toast__icon {
font-size: 40px
}

.van-toast__loading {
padding: 4px;
color: #fff
}

.van-toast__text {
margin-top: 8px
}
</style>
